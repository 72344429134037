import './OpcoesDeAu.css';

const OpcoesDeAu = (props) => {
  const nomeDaClasse = 'opcoes-de-au ' + props.className;
  const botaoAcionado = () => {
    props.estadoDoBotao(props.au)
  }
  return(
    <div className={nomeDaClasse} onClick={botaoAcionado}>
      <p className='opcoes-de-au__texto'>{props.au}</p>
    </div>
  )
}

export default OpcoesDeAu;
