import { useState } from 'react';
import './MenuBar.css';

import MenuOption from './MenuOption/MenuOption'

const MenuBar = (props) => {
  const [estadoDoBotao, setEstadoDoBotao] = useState('EMOÇÕES')
  const pegarOpcaoDoBotao = (escolha) => {
    props.pegarOpcaoPrincipalMenu(escolha);
    props.receberInformacaoMenu('neutro');
    setEstadoDoBotao(escolha);
  }

  let emocao = ''
  let aus = ''
  let outras = ''

  function range(start, end) {
    let ans = [];
    for (let i = start; i <= end; i++) {
        ans.push('' + i);
      }
    return ans;
  }

  const numeros = range(0, 50);
  if(estadoDoBotao === 'AUS' || numeros.includes(props.estadoDaEmocao)) {
    aus = 'acionado'
  }else if(estadoDoBotao === 'EMOÇÕES') {
    emocao = 'acionado'
  }else if(estadoDoBotao === 'OUTRAS') {
    outras = 'acionado'
  }

  return(
    <div className='menu-bar'>
      <MenuOption className={'left-box ' + emocao} texto='EMOÇÕES' pegarOpcao={pegarOpcaoDoBotao} />
      <MenuOption className={'center-box ' + aus} texto='AUS' pegarOpcao={pegarOpcaoDoBotao} />
      <MenuOption className={'right-box ' + outras} texto='OUTRAS' pegarOpcao={pegarOpcaoDoBotao} />
    </div>
  );
};

export default MenuBar;
