import './PesquisaDeSatisfacao.css';
import { useState } from 'react';
import firebase from "firebase";
import "firebase/auth"

var firebaseConfig = {
  apiKey: "AIzaSyAqqCMONTvdeToiiqQYxH8I5OBn_-wR7VM",
  authDomain: "facing-tech-ffbb6.firebaseapp.com",
  projectId: "facing-tech-ffbb6",
  storageBucket: "facing-tech-ffbb6.appspot.com",
  messagingSenderId: "18092821396",
  appId: "1:18092821396:web:612883ef48fe16f21d9d81",
  measurementId: "G-7YSWV2GPD4"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}else {
   firebase.app();
}

var db = firebase.firestore();

const PesquisaDeSatisfacao = () => {
  const [estadoDaPesquisaDeSatisfacao, setEstadoDaPesquisaDeSatisfacao] = useState(false);
  const [textoDaPesquisa, setTextoDaPesquisa] = useState('');

  const ativarPesquisaDeSatisfacao = () => {
    setEstadoDaPesquisaDeSatisfacao(!estadoDaPesquisaDeSatisfacao)
  }

  const atualizarTextoDaPesquisa = () => {
    setTextoDaPesquisa(document.getElementById('input-texto').value)
  }

  const enviarPesquisa = () => {
    setEstadoDaPesquisaDeSatisfacao(!estadoDaPesquisaDeSatisfacao)
    db.collection('feedbacks')
      .add({
        feedback: textoDaPesquisa
      })
      .then(() => {
        alert('Sua pesquisa foi enviada')
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      })
  }

  if(estadoDaPesquisaDeSatisfacao) {
    return(
      <>
        <div className='pesquisa-de-satisfacao__background' onClick={ativarPesquisaDeSatisfacao}></div>
        <div className='pesquisa-de-satisfacao'>
          <div className='pesquisa-de-satisfacao__exit' onClick={ativarPesquisaDeSatisfacao}>
            <div className='pesquisa-de-satisfacao__exit-1'></div>
            <div className='pesquisa-de-satisfacao__exit-2'></div>
          </div>
          <div className='pesquisa-de-satisfacao__centro'>
            <h2 className='pesquisa-de-satisfacao__titulo'>Pesquisa de satisfação:</h2>
            <textarea id='input-texto' className='pesquisa-de-satisfacao__feedback' placeholder='Conte o que achou...' onChange={atualizarTextoDaPesquisa}></textarea>
            <div className='pesquisa-de-satisfacao__submit' onClick={enviarPesquisa}>Enviar</div>
          </div>
        </div>
      </>
    )
  } else {
    return(
    <div className='pesquisa-de-satisfacao__botao' onClick={ativarPesquisaDeSatisfacao}>
      <p className='pesquisa-de-satisfacao__icone'>?</p>
    </div>
    )
  }
}

export default PesquisaDeSatisfacao;
