import React from 'react';
import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyAqqCMONTvdeToiiqQYxH8I5OBn_-wR7VM",
  authDomain: "facing-tech-ffbb6.firebaseapp.com",
  projectId: "facing-tech-ffbb6",
  storageBucket: "facing-tech-ffbb6.appspot.com",
  messagingSenderId: "18092821396",
  appId: "1:18092821396:web:612883ef48fe16f21d9d81",
  measurementId: "G-7YSWV2GPD4"
};

const start = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }else {
     firebase.app();
  }
}

const FirebaseStart = React.createContext({
  firebaseStart: start
});

export default FirebaseStart
