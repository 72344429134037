import './EmotionChoice.css';

const EmotionChoice = (props) =>{
  let nomeDaClasse = 'emotion-choice ' + props.className

  const buttonActivationHandler = () => {
    props.receberInformacao(props.className.split(" ")[0])
  }
  const emocaoEscolida = props.className.split(" ")[0]

  return(
    <div className={nomeDaClasse} onClick={buttonActivationHandler}>
      <p className='emotion-choice__text'>{emocaoEscolida}</p>
    </div>
  )
}

export default EmotionChoice;
