import './MenuOption.css';

const MenuOption = (props) => {
  let nameClass = 'menu-option ' + props.className;
  const funcaoTeste = () => {
    props.pegarOpcao(props.texto);
  }
  return(
    <div className= {nameClass} onClick={funcaoTeste}>
      <p className='menu-option__text'>{props.texto}</p>
    </div>
  )
}

export default MenuOption;
