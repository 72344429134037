import { useSpring, animated } from 'react-spring';
import { useState } from 'react';
import './Instrucoes.css';
import OpcoesDeInstrucoes from './OpcoesDeInstrucoes/OpcoesDeInstrucoes';
import RealilzarLogin from './RealilzarLogin/RealilzarLogin';
import firebase from "firebase";

const Instrucoes = (props) => {
  const [opcaoEscolida, setOpcaoEscolida] = useState('introducao');
  const [estadoDeLogin, setEstadoDeLogin] = useState(false);
  const mudarOpcao = (opcao) => {
    setOpcaoEscolida(opcao);
  }

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setEstadoDeLogin(true);
    } else {
      setEstadoDeLogin(false);
    }
  })

  const animacao = useSpring({
    from: {
      transform: 'scale(0)'
    },
    to: {
      transform: 'scale(1)'
    },
    reset: true
  })

  function range(start, end) {
    let ans = [];
    for (let i = start; i <= end; i++) {
        ans.push('' + i);
      }
    return ans;
  }

  const numeros = range(0, 50);

  let estadoDoEspecifico = ''

  if(props.estadoDaEmocao !== 'neutro') {
    estadoDoEspecifico = <OpcoesDeInstrucoes className='instrucoes__especifico' colherOpcao={mudarOpcao} estadoDaEmocao={props.estadoDaEmocao} />;
  }

  if(opcaoEscolida === 'especifico') {

    if(props.estadoDaEmocao === 'alegria') {
      if(estadoDeLogin) {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <div className='instrucoes__caixa-de-texto'>
              <h2 className='instrucoes__titulo'>Alegria</h2>
              <p className='instrucoes__texto'>
              <b>Prototipagem na face</b>
              <br />
              <ul>
                <li>6 + 12</li>
                <li>12C/D</li>
              </ul>
              <br />
              Alegria é uma família de emoções, há mais de uma dúzia de emoções relacionadas a ela. Todas elas estão associadas a sensações agradáveis, no entanto, elas têm seus próprios gatilhos e suas peculiaridades. Tais emoções têm uma certa correlação com o ganho de prazer ou com evitar uma dor. As emoções da família da alegria são:
              <br />

              <ol>
                <li><b>Prazer sensorial: </b>Os gatilhos dessa emoção são prazeres sensoriais como ver uma paisagem agradável, ouvir algo prazeroso, comer algo gostoso, farejar algo cheiroso. Os sentidos sensoriais são visual, tátil, olfativo, auditivo e gustativo.</li>
                <br />
                <li><b>Diversão: </b>É uma das emoções agradáveis mais simples. Todos se sentem bem quando se divertem. Ela pode variar de intensidade indo de risadas até choro.</li>
                <br />
                <li><b>Contentamento: </b>Essa emoção é reproduzida quando está dando tudo certo, quando sentimos que não temos que fazer nada ou quando estamos relaxados. Geralmente, se observa um relaxamentos dos músculos faciais.</li>
                <br />
                <li><b>Entusiasmo: </b>Essa emoção é evocada quando há uma novidade ou um desafio. Ela é uma forma mais intensa do interesse. O entusiasmo pode estar presente com outras emoções como medo, uma explosão de raiva, fúria e outras emoções agradáveis, de acordo com Paul Ekman.</li>
                <br />
                <li><b>Alívio: </b>Ele ocorre quando estávamos sobre uma tensão emocional elevada e ela se dissipa. O alívio acontece mais no medo quando algo que temíamos não se materializa. Ele pode se manifestar depois de uma angústia, depois de uma tensão sexual ou depois de gargalhadas. Ela é sempre precedida de outra emoção.</li>
                <br />
                <li><b>Assombro: </b>Está ligado ao incrível, incompreensível, fascinante, grandioso ou a raridade. De acordo com Darwin, a sensação física mais forte relacionada ao assombro é a pele arrepiada.</li>
                <br />
                <li><b>Admiração: </b>A admiração por pessoas é uma sensação intrinsecamente prazerosa. A admiração é muito semelhante ao assombro, porém, a admiração gera ação, nós sentimos movidos pela pessoa que admiramos, já o assombro não gera o impulso para a ação.</li>
                <br />
                <li><b>Êxtase: </b>Os gatilhos dessa emoção são o estado obtido na meditação, a vivência com a natureza e ainda uma experiência sexual com um parceiro. Sempre que o êxtase é vivenciado é em alta intensidade.</li>
                <br />
                <li><b>Fiero (Orgulho): </b>Tal emoção é sentida quando conquistamos um objetivo desafiador e incrível. Ela é vivenciada quando conquistamos algo que precisamos nos empenhar para conseguir.</li>
                <br />
                <li><b>Naches: </b>É o orgulho de ver o filho conseguido alcançar algo importante. Ela acontece quando um pai experiencia a conquista de um filho.</li>
                <br />
                <li><b>Elevação: </b>Ver um ato inesperado de bondade, gentileza e compaixão desencadeia a elevação.</li>
                <br />
                <li><b>Gratidão: </b>É o apreço de uma doação altruísta que ajuda o outro.</li>
                <br />
                <li><b>Schadenfraude: </b>É a sensação de saber que o seu pior inimigo sofreu algo negativo.</li>
              </ol>
            </p>
            </div>
          </section>
        );
      } else {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <RealilzarLogin colherEstadoDaCaixaDeCadastro={props.colherEstadoDaCaixaDeCadastro} />
          </section>
        )
      }
    }else if(props.estadoDaEmocao === 'tristeza') {
      if(estadoDeLogin) {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <div className='instrucoes__caixa-de-texto'>
              <h2 className='instrucoes__titulo'>Tristeza</h2>
              <p className='instrucoes__texto'>
                <b>Prototipagem na face</b>
                <br />
                <ul>
                  <li>1 + 4 + 11 + 15B com ou sem 54 + 64</li>
                  <li>1 + 4 + 15 com ou sem 54 + 64</li>
                  <li>6 + 15 com ou sem 54 + 64</li>
                </ul>
                <br />
                <b>Principais Variantes</b>
                <br />
                <ul>
                  <li>1 + 4 + 11 com ou sem 54 + 64</li>
                  <li>1 + 4 + 15B com ou sem 54 + 64</li>
                  <li>1 + 4 + 15B + 17 com ou sem 54 + 64</li>
                  <li>11 + 15B com ou sem 54 + 64</li>
                  <li>11 + 17</li>
                </ul>
                25 ou 26 podem ocorrer com todos as prototipagens e com a maioria das variantes.
                <br />
                <br />
                <br />
                Tristeza é a emoção da perda. O gatilho universal da tristeza é a perda de algo importante para o sujeito. A tristeza é a emoção com a duração mais longa. A tristeza de baixa intensidade pode durar alguns segundo ou minutos. Enquanto a de alta intensidade tem um período mais prolongado e, vem em ondas e não de forma contínua. A tristeza tira o tônus muscular do ser humano.
                <br />
                <br />
                Os motivos evolutivos da tristeza são empatia e ajuda dos outros, enriquecer a experiência da perda para que ela não volte a se repetir e poupar energia. Uma alegria muito intensa, segundo Paul Ekman, pode levar o ser humano ao choro. Isso ocorre pelo fato da alegria intensificada subjugar o sistema emocional e, por conseguinte, ela gera angústia.
                <br />
                <br />
                Em uma tristeza muito intensa o AU 11 pode estar presente. A maioria das pessoas tem dificuldade de controlar o AU 1, o que dificulta forjar esta emoção. O olhar pode ir para baixo na tristeza e pode haver uma queda na pálpebra superior (AU 41). O AU 15 isolado em alta intensidade, provavelmente, não é tristeza, mas sim um sinal de negação ou descrença. Em um cenário de pré-choro, o AU 17 está presente. O AU 16 isolado em alta intensidade pode ser um sinal de incerteza ou de sedução. O aperto da boca (AU 24) pode ser usado quando a pessoa busca esconder a emoção de tristeza.
              </p>
            </div>
          </section>
        );
      } else {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <RealilzarLogin colherEstadoDaCaixaDeCadastro={props.colherEstadoDaCaixaDeCadastro} />
          </section>
        )
      }
    }else if(props.estadoDaEmocao === 'raiva') {
      if(estadoDeLogin) {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <div className='instrucoes__caixa-de-texto'>
              <h2 className='instrucoes__titulo'>Raiva</h2>
              <p className='instrucoes__texto'>
                <b>Prototipagem na face</b>
                <br />
                <ul>
                  <li>4 + 5 + 7 + 10 + 22 + 23 + 25, 26</li>
                  <li>4 + 5 + 7 + 10 + 23 + 25, 26</li>
                  <li>4 + 5 + 7 + 23 + 25, 26</li>
                  <li>4 + 5 + 7 + 17 + 23</li>
                  <li>4 + 5 + 7 + 17 + 24</li>
                  <li>4 + 5 + 7 + 23</li>
                  <li>4 + 5 + 7 + 24</li>
                </ul>
                <br />
                <b>Principais Variantes</b>
                <br />
                <br />
                Qualquer prototipagem anterior sem os seguintes AUs:
                <br />
                AUs: 4, 5, 7, ou 10.
                <br />
                <br />
                <br />
                A raiva é a emoção do ataque e da violência. Um gatilho comum em babes para gerar raiva é segurar seus braços e pernas evitando que ele alcance o que deseja. Igualmente aos bebes, o gatilho universal da raiva é quando algo ou alguém nos impede de alcançarmos nossos objetivos.
                <br />
                <br />
                Dois fatores que podem agravar a raiva são se a pessoa que interfere nos nossos objetivos nós impedir de forma deliberada e não solicitada. A raiva não tem como origem somente pessoas. A raiva também pode ser originada de objetos, situações ou contextos.
                <br />
                <br />
                Na situação de alguém querer te ferir fisicamente ou moralmente, duas emoções comuns são a raiva e o medo. Já no contexto de rejeição, se espera observar raiva e tristeza da pessoa desejada. Tal raiva pode levar a violência contra o parceiro desejado. No cenário de desapontamento, a raiva também está muito presente. Um gatilho onde a raiva está muito presente é na injustiça. A raiva controla, castiga e revida.
                <br />
                <br />
                A raiva está diretamente relacionada em gerar dor nos outros, essa dor pode ser física e/ou emocional. A demonstração da raiva está mais presente entre conhecidos do que entre estranhos por ser mais seguro. Não é surpreendente verificar a raiva entre pessoas que não compartilham de crenças ou cultura semelhante.
                <br />
                <br />
                O comportamento natural e evolutivo da raiva, de acordo com Paul Ekman, é a violência e a agressão. Logo, podemos verificar que as crianças com pouco controle sobre o seu impulso violento, praticam mais agressões e com o tempo, elas aprendem a controlar seus impulsos de agressão.
                <br />
                <br />
                As palavras e ações danosas quando alguém está com raiva são condenáveis, mas compreensíveis. Já essas ações na ausência da raiva não são compreensíveis e podem significar um desvio de personalidade.
                <br />
                <br />
                Apesar de a raiva estar relacionada com a violência, ela tem suas utilidades. Algumas delas são: desmotivar agressores, se defender, comunicar que algo desagradou, motivação e se proteger te injustiças.
                <br />
                <br />
                Causas comuns da violência doméstica onde a raiva está envolvida de acordo com Helen Cronin são a infidelidade, desconfiança e a ameaça ou rejeição real de um parceiro. A violência pode ser de dois tipos o impulsivo e o premeditado.
                <br />
                <br />
                Sinais da raiva são as expressões faciais mostradas acima, olhar fixo no algo da raiva, aperto da boca (AU 24) maxilar tensionado para frente (AU 29). E em um cenário de pré-ataque se observa o afinamento dos lábios (AU 23), e um levantamento agudo da pálpebra superior (AU 5) com a presença do AU 4.
                <br />
                <br />
                O AU 4 isolado pode significar uma emoção de raiva, contudo o contexto deve ser considerado. A presença desse movimento muscular pode sugerir concentração, perplexidade, determinação ou confusão. Como explica Charles Darwin, o AU 4 é o músculo do esforço físico ou mental. AU 44 pode simbolizar uma raiva em baixa intensidade, uma raiva contida ou concentração.
                <br />
                <br />
                A raiva violenta não se constrói de um segundo para o outro, essa raiva vai escalando de forma gradativa até chegar no ponto de se tornar violenta. Ter a habilidade de reconhecer quando a raiva está crescendo nos outros por meio do reconhecimento de expressões faciais pode aumentar a chance de se evitar a violência de tal raiva.
              </p>
            </div>
          </section>
        );
      } else {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <RealilzarLogin colherEstadoDaCaixaDeCadastro={props.colherEstadoDaCaixaDeCadastro} />
          </section>
        )
      }
    }else if(props.estadoDaEmocao === 'nojo') {
      if(estadoDeLogin) {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
              <div className='instrucoes__caixa-de-texto'>
                <h2 className='instrucoes__titulo'>Nojo</h2>
                <p className='instrucoes__texto'>
                  <b>Prototipagem na face</b>
                  <br />
                  <ul>
                    <li>9</li>
                    <li>9 + 16 + 25, 26</li>
                    <li>9 + 17</li>
                    <li>10</li>
                    <li>10 + 16 + 25, 26</li>
                    <li>10 + 17</li>
                  </ul>
                  <br />
                  Nojo é a reação de querer se afastar de algo que pode trazer dano. Os gatilhos mais comuns de nojo são comidas, cheiros, sons, tato, ideias, pessoas, ações, pessoas deformadas, sangue, feridas, pessoas muito feias e produtos corporais como fezes, vomito e musgo.
                  <br />
                  <br />
                  Nojo é uma das emoções mais fácies de se induzir. A partir do momento que um fluido sai do nosso corpo ele passa a ser nojento. O nojo somente está presente entre os 4 e oito anos de idade. A aversão pode estar presente quando a pessoa sente raiva.
                  <br />
                  <br />
                  A aversão social é uma emoção muito similar ao nojo, porém ela nos afasta daquilo que é moralmente censurável, enquanto o nojo nos afasta daquilo que gera contaminação em sua maioria. Algumas categorias de aversão interpessoais são o estranho, o desafortunado, o doente e o moralmente corrompido. A expressão da aversão é levemente diferente do nojo. Na aversão, o AU 9 não está envolvido, somente o AU 10.
                  <br />
                  <br />
                  A aversão enfastiada é a aversão que acontece entre marido e mulher. O nojo e o desprezo entre os membros de um relacionamento mostra um desinteresse no outro e pode significar um fim próximo do relacionamento.
                  <br />
                  <br />
                  A intimidade aumenta a tolerância do nojo entre as pessoas envolvidas no relacionamento e marca um compromisso pessoal. Quando sentimos um nojo intenso em relação a alguem, nos desumanizamos a pessoa e não vemos ela como seres humanos. O nojo diminui a empatia e a compaixão.
                  <br />
                  <br />
                  <br />
                  Existem 3 tipos de empatia:
                  <ul>
                    <li>Empatia cognitiva: Saber o que o outro está sentindo</li>
                    <li>Empatia emocional: Sentir o que o outro está sentindo</li>
                    <li>Empatia compassiva: Querer ajudar o outro a lidar com a situação ou com suas emoções</li>
                  </ul>
                  As duas últimas categorias de empatia dependem da empatia cognitiva para se manifestarem.
                  <br />
                  <br />
                  A expressão do nojo pode ser confundida com a da raiva. Uma forma fácil de diferenciar uma da outra é a pressão dos lábios (AU 24) que está somente presente na raiva e no nojo os lábios estão relaxados. Outra forma de diferenciar, é a presença do AU 5 que não está contida no nojo, somente na raiva.
                </p>
              </div>
          </section>
        );
      } else {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <RealilzarLogin colherEstadoDaCaixaDeCadastro={props.colherEstadoDaCaixaDeCadastro} />
          </section>
        )
      }
    }
    else if(props.estadoDaEmocao === 'desprezo') {
      if(estadoDeLogin) {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <div className='instrucoes__caixa-de-texto'>
              <h2 className='instrucoes__titulo'>Desprezo</h2>
              <p className='instrucoes__texto'>
                <b>Prototipagem na face</b>
                <br />
                <ul>
                  <li>14</li>
                  <li>2 + 14</li>
                </ul>
                <br />
                Desprezo é a emoção da superioridade e da comparação. Essa emoção é acionada quando o ser humano se percebe como superior moralmente ao outro. O alvo do desprezo pode ser pessoas ou ações. Há, também, o desprezo de baixo para cima, em que o subordinado se vê superior ao seu chefe em um atributo específico. Ela é a emoção mais fácil de se reconhecer nos outros, porém a mais difícil de descobrir o que a originou.
                <br />
                <br />
                O desprezo, como o nojo, tem intensidades. Entretanto, o desprezo em alta intensidade não chega perto do nojo em alta intensidade em força. É comum se acreditar que o desprezo é negativo, no entanto, ela pode ser prazerosa para a pessoa que a vivência. Essa emoção pode ter nós mesmos como alvo, nos vendo como superiores ou inferiores.
                <br />
                <br />
              </p>
            </div>
          </section>
        );
      } else {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <RealilzarLogin colherEstadoDaCaixaDeCadastro={props.colherEstadoDaCaixaDeCadastro} />
          </section>
        )
      }
    }else if(props.estadoDaEmocao === 'medo') {
      if(estadoDeLogin) {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <div className='instrucoes__caixa-de-texto'>
              <h2 className='instrucoes__titulo'>Medo</h2>
              <p className='instrucoes__texto'>
                <b>Prototipagem na face</b>
                <br />
                <ul>
                  <li>1 + 2 + 4 + 5 + 20 + 25, 26 ou 27</li>
                  <li>1 + 2 + 4 + 5 + 25, 26, ou 27</li>
                </ul>
                <br />
                <b>Principais Variantes</b>
                <br />
                <ul>
                  <li>1 + 2 + 4 + 5 + L or R20 + 25, 26, ou 27</li>
                  <li>1 + 2 + 4 + 5</li>
                  <li>1 + 2 + 5, com ou sem 25, 26, 27</li>
                  <li>5 + 20 com ou sem 25, 26, 27</li>
                </ul>
                <br />
                Medo se manifesta quando há uma ameaça imediata ou iminente. O tema universal do medo é uma ameaça de dano futuro. O medo te prepara para fugir ou para se esconder, isto é congelar ou fugir. Quando não é possível fugir ou congelar, o provável é que vamos sentir raiva e vamos atacar. Não é incomum sentir medo e raiva em sucessão.
                <br />
                <br />
                Há diferenças entre a ameaça imediata e a iminente. Sobre o comportamento da pessoa em uma ameaça imediata, ela vai fugir ou congelar, já na ameaça iminente ela vai ficar mais atenta e vai aumentar a tensão muscular. Em relação a analgesia, na ameaça imediata, acontece uma anestesia devido à uma sobrecarga cognitiva do cérebro em tentar evitar a ameça e dessa forma não sobra carga cognitiva para o cérebro processar a dor. Já na ameaça iminente, há uma aumento da dor percebida.
                <br />
                <br />
                Quando algo pode ser feito em uma ameça imediata, o medo é substituído por um estado de foco elevado para evitar a ameaça, porém quando nada pode ser feito o medo acontece em maior intensidade. O medo vai ter uma intensidade maior dependendo da gravidade da ameça futura. Se houver muito em jogo e a ameaça for muito grande, a emoção de medo será mais intensa.
                <br />
                <br />
                O estado de ânimo relacionado com o medo é o apreensivo e os traços de personalidade emocional são a tímida ou a envergonhada. Distúrbios emocionais relacionados ao medo são fobias, TEPT (Transtorno por estresse pós traumático), ataques de pânico e ansiedade patológica.
                <br />
                <br />
                <br />
                Variações da emoção:
                <br />
                <ul>
                <li>AU 5 em baixa intensidade - sinal de atenção ou interesse</li>

                <li>AU 1 e 2 pode ser um sinal conversacional ou pode ser um sinal de descrença</li>

                <li>Quando a pessoa sabe a resposta da pergunta que ela está fazendo, é provável que ela erga as sombrancelhas - AU 1 e 2</li>

                <li>AU 5 isolado em alta intensidade é uma expressão de medo</li>
                </ul>
              </p>
            </div>
          </section>
        );
      } else {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <RealilzarLogin colherEstadoDaCaixaDeCadastro={props.colherEstadoDaCaixaDeCadastro} />
          </section>
        )
      }
    }else if(props.estadoDaEmocao === 'surpresa') {
      if(estadoDeLogin) {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <div className='instrucoes__caixa-de-texto'>
              <h2 className='instrucoes__titulo'>Surpresa</h2>
              <p className='instrucoes__texto'>
                <b>Prototipagem na face</b>
                <br />
                <ul>
                  <li>1 + 2 + 5B + 26</li>
                  <li>1 + 2 + 5B + 27</li>
                </ul>
                <br />
                <b>Principais Variantes</b>
                <br />
                <ul>
                  <li>1 + 2 + 5B</li>
                  <li>1 + 2 + 26</li>
                  <li>1 + 2 + 27</li>
                  <li>5B + 26</li>
                  <li>5B + 27</li>
                </ul>
                <br />
                Surpresa é a emoção mais breve, ela dura no máximo alguns segundos. Um evento que se desenvolve lentamente não nos deixa surpresos. Essa emoção é causada pela quebra de expectativa ou por algo fora da normalidade. Quando sentimos tal emoção, o cérebro mobiliza a sua atenção para fazer sentido do fato que nos surpreendeu. Pelo fato de ela ser a emoção com menor duração, ela se torna difícil de ocultar.
                <br />
                <br />
                A expectativa de um evento pode ser ativa ou passiva. No primeiro caso, você está esperando um evento acontecer. Já no segundo, você não esperava o evento, mas ele é familiar naquele contexto. A repetição de um acontecimento torna ele menos surpreendente e mais familiar, tornando esse acontecimento uma expectativa passiva. Se a repetição for mais frequente, o acontecido se torna uma expectativa ativa.
                <br />
                <br />
                Um evento é surpreendente porque quando os nossos autoavaliadores percebem tal evento, eles buscam na memória algo que se assemelhe ao ocorrido e se nada similar for recuperado, o episódio se torna surpreendente de acordo com Daniel Kahneman.
                <br />
                <br />
                <br />
                <b>Espanto X Surpresa</b>
                <br />
                <br />
                O espanto, diferente da surpresa, não é uma emoção, mas sim um reflexo físico. Ele tem um timing fixo de ¼ de segundo e some em ½ segundo. O espanto é causado por um barulho muito alto e repentino. Se o barulho for esperado, o espanto acontece, porém, em menor intensidade. A surpresa, por outro lado, não é estimulada se o evento for esperado.
                <br />
                <br />
                <br />
                <b>Medo X Surpresa</b>
                <br />
                <br />
                As expressões na face do medo e da surpresa são muito semelhantes e isso causa confusão entre as pessoas. A principal diferença é, na surpresa, a face está menos ‘tensionada’. Os AUs que estão presentes no medo, mas não na surpresa são: AUs: 4 + 7 + 20. No medo, o AU 5 e o AU 21 estão em uma intensidade mais elevada em comparação com a surpresa.
                <br />
                <br />
                <br />
                <b>Surpresa Falsa</b>
                <br />
                <br />
                A surpresa, como foi exposto antes, tem uma duração muito breve. Se o tempo de offset da emoção durar mais do que poucos segundos, é uma surpresa forjada. Outro atributo da surpresa falsa é a abertura da boca. Se a abertura da boca for exacerbada, é um sinal importante que indica uma surpresa induzida.
              </p>
            </div>
          </section>
        );
      } else {
        return(
          <section className='instrucoes'>
            <div className='instrucoes__opcoes'>
              <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
              <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
              {estadoDoEspecifico}
            </div>
            <RealilzarLogin colherEstadoDaCaixaDeCadastro={props.colherEstadoDaCaixaDeCadastro} />
          </section>
        )
      }
    }else if(props.estadoDaEmocao === '1' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 1</h2>
            <p className='instrucoes__texto'>
              <b>Levantador da parte interna das sobrancelhas</b>
              <br />
              <br />
              Um músculo largo no epicrânio e na testa levanta a parte interna da sobrancelha. O músculo corre verticalmente do topo da cabeça até a sobrancelha e cobre literalmente a testa inteira. A parte medial desse músculo (AU1) pode agir de forma independente da parte lateral desse músculo (AU 2).
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 1:</b>
              <ol>
              <li>Puxa a porção interna da sobrancelha para cima.</li>
              <br />
              <li>Para muitas pessoas, produz uma forma obliquá na sobrancelha.</li>
              <br />
              <li>Gera rugas horizontais na pele do centro da testa. Essas rugas estão limitadas no centro da testa.</li>
              <br />
              <li>É comum a parte externa da sobrancelha se mover um pouco para o meio. É importante diferenciar esse movimento da parte externa da sobrancelha para o meio do movimento para cima (AU 2).</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '2' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 2</h2>
            <p className='instrucoes__texto'>
              <b>Levantador da parte externa das sobrancelhas</b>
              <br />
              <br />
              O músculo responsável pelo movimento AU 2 é a porção lateral do mesmo músculo do AU 1. Esse movimento eleva a parte lateral da sobrancelha para cima na direção do cabelo.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 2:</b>
              <ol>
              <li>Puxa a porção lateral da sobrancelha para cima.</li>
              <br />
              <li>Produz uma forma de arco nas sobrancelhas.</li>
              <br />
              <li>A parte lateral que pele que cobre o olho é esticada para cima.</li>
              <br />
              <li>Em algumas pessoas, causa rugas horizontais acima da porção lateral da sobrancelha.</li>
              <br />
              <li>A parte interna da sobrancelha pode se mover levemente devido ao movimento do AU 2.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '4' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 4</h2>
            <p className='instrucoes__texto'>
              <b>Abaixar a parte interna das sobrancelhas</b>
              <br />
              <br />
              O principal músculo responsável pelo movimento do AU 4 é um que passa de forma obliquá pela testa, ele surge perto da parte superior do nariz e vai até à parte superior da porção externa da sobrancelha.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 4:</b>
              <ol>
              <li>Abaixa a sobrancelha. Em instâncias diferentes, somente a parte interna da sobrancelha pode se mover, há casos em que parece que a sobrancelha na totalidade está se movendo.</li>
              <br />
              <li>Empurra a pele que cobre os olhos para baixo e pode estreitar a abertura dos olhos.</li>
              <br />
              <li>Puxa as sobrancelhas para perto uma da outra.</li>
              <br />
              <li>Produz rugas verticais entre as sobrancelhas. Em algumas pessoas, essas rugas podem não ser verticais, mas serem em 45 gruas.</li>
              <br />
              <li>Podem produzir rugas oblíquas na parte central da testa.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '5' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 5</h2>
            <p className='instrucoes__texto'>
              <b>Levantador da pálpebra superior</b>
              <br />
              <br />
              Esse AU puxa a pálpebra superior para cima. Quando a pálpebra superior relaxa, ela cai sobre os olhos (AU 43) e quado a pálpebra está completamente relaxada ela fecha os olhos. Quando os olhos estão abertos normalmente há uma ativação do mesmo músculo do AU 5, entretanto, o AU 5 somente é classificado quando a pálpebra superior se eleva mais do que o normal.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 5:</b>
              <ol>
              <li>Aumenta a abertura dos olhos.</li>
              <br />
              <li>Sobe a pálpebra superior que pode sumir parcial ou totalmente da vista. Em algumas pessoas a pálpebra superior não pode ser vista normalmente e, logo, ela não pode ser usada como parâmetro para determinar o movimento do AU 5.</li>
              <br />
              <li>Como resultado do levantamento da pálpebra superior, uma porção maior do olho vai ficar exposta. Quando vai ficar exposto depende da intensidade do AU 5 e o quando o olho fica exposto normalmente. A esclera também pode ficar mais exposta devido ao AU 5.</li>
              <br />
              <li>Como resultado do levantamento da pálpebra superior, a forma da parte superior da borda do olho muda conforme a porção medial e/ou lateral são puxadas para cima. Essa mudança de forma resulta em uma exposição maior da esclera na área superior medial e lateral do olho.</li>
              <br />
              <li>A pálpebra inferior também sobe minuciosamente quando há um AU 5 forte. Isso acontece porque o AU 5 puxa a pele em volta do olho, inclusive a pálpebra inferior, para cima. É importante deixar claro que puxar a pele da pálpebra inferior para cima pelo AU 5 não envolve a tensão do músculo AU 7 que se encontra em baixo da pele da pálpebra inferior.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '6' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 6</h2>
            <p className='instrucoes__texto'>
              <b>Levantador das bochechas e compressor da pálpebra</b>
              <br />
              <br />
              O músculo por trás do AU 6 é um que circula o olho e tem uma circunferência que se estende da sobrancelha até embaixo da parte inferior do sulco do olho. O AU 6 puxa a pele na direção do olho.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 6:</b>
              <ol>
              <li>Arrasta a pele da têmpora e das bochechas na direção dos olhos.</li>
              <br />
              <li>Levanta o triângulo infraorbital, puxando as bochechas para cima.</li>
              <br />
              <li>Emburra a pele que envolve os olhos na direção da cavidade ocular que pode estreitar a abertura dos olhos. </li>
              <br />
              <li>Podem aparecer rugas e pés de galinha na pele entre a têmpora e o olho.</li>
              <br />
              <li>Aprofunda a sulco da pálpebra inferior.</li>
              <br />
              <li>Pode abaixar a porção lateral da sobrancelha em certa medida.</li>
              <br />
              <li>Um AU 6 intenso pode:</li>
              <ul>
                <li>Tornar mais evidente ou mais profundo o sulco nasolabial.</li>
                <br />
                <li>Levantar a porção exterior do lábio superior até certa medida.</li>
                <br />
                <li>Tornar evidente ou mais profundo o sulco infraorbital.</li>
              </ul>
              <br />
              <li>O AU 6 pode puxar a pele por cima do músculo responsável pelo AU 4.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '7' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 7</h2>
            <p className='instrucoes__texto'>
              <b>Levantador da pálpebra inferior</b>
              <br />
              <br />
              O músculo que se encarrega de realizar o AU 7 é um que circula a órbita do olho. Esse músculo está posicionado perto das pálpebras. Quando ele está contraído, AU 7 puxa a pálpebra inferior, superior e alguma pele adjacente embaixo do olho na direção do canto interno do olho.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 7:</b>
              <ol>
              <li>Pálpebras tensionadas.</li>
              <br />
              <li>Estreitamento da abertura dos olhos.</li>
              <br />
              <li>É mais aparente na pálpebra inferior do que na superior.</li>
              <br />
              <li>Levanta a pálpebra inferior cobrindo mais os olhos do que normalmente.</li>
              <br />
              <li>A pálpebra inferior levantada pode se tornar mais reta do que curvada ou a forma da pálpebra inferior pode mudar de um U para um U invertido.</li>
              <br />
              <li>O levantamento da pele da pálpebra inferior causa uma protuberância na pálpebra inferior</li>
              <br />
              <li>Pode deixar o sulco da pálpebra inferior evidente como uma linha ou como rugas ou se sulco é permanente ele se torna mais profundo.</li>
              <br />
              <li>Quando o AU 7 está na sua máxima intensidade, aparece estrabismo nos olhos.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '9' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 9</h2>
            <p className='instrucoes__texto'>
              <b>Corrugador do nariz</b>
              <br />
              <br />
              O músculo que realiza o movimento AU 9 se estende da raiz do nariz descendo até a área em baixo das asas do nariz. Quando contraído, esse músculo puxa a pele da área embaixo das asas do nariz na direção da raiz do nariz. Geralmente, o AU 9 puxa a pele da porção interna da sobrancelha abaixando ela.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 9:</b>
              <ol>
              <li>Puxa a pele ao lado do nariz para cima na direção da raiz do nariz causando rugas na pele ao lado do nariz e na raiz do nariz.</li>
              <br />
              <li>Puxa o triângulo infraorbital para cima causando rugas no sulco infraorbital e cria uma protuberância na pele embaixo dos olhos.</li>
              <br />
              <li>Abaixa a porção medial das sobrancelhas que tende a esconder qualquer movimento do AU 1.</li>
              <br />
              <li>Estreita a abertura dos olhos.</li>
              <br />
              <li>Puxa o centro do lábio superior para cima. Se o movimento for forte, os lábios vão se separar. Caso contrário, os lábios devem permanecer fechados.</li>
              <br />
              <li>Amplia e levanta as asas do nariz.</li>
              <br />
              <li>Pode afundar o sulco nasolabial se a ação for intensa do AU 9.</li>
              <br />
              <li>Já que na maioria das vezes que o AU 9 é acionado, há um rebaixamento da parte central da sobrancelha, você não deve marcar AU 4 + 9 a não ser que tenha evidencias que o AU 4 foi acionado.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '10' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 10</h2>
            <p className='instrucoes__texto'>
              <b>Levantador do lábio superior</b>
              <br />
              <br />
              O músculo incumbido de realizar o movimento AU 10 está próximo ao AU 9, mas ele está posicionado mais lateralmente. Ele surge do centro do triângulo infraorbital e termina na área do sulco nasolabial. No AU 10, a pele sobre o lábio superior é puxada para cima e na direção das bochechas, puxando, assim, o lábio superior para cima.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 10:</b>
              <ol>
                <li>Levanta o lábio superior. O centro do lábio superior é puxado para cima, a porção lateral do lábio superior é puxado para cima, porém, em uma intensidade maior.</li>
                <br />
                <li>Causa um ângulo de U invertido no lábio superior.</li>
                <br />
                <li>Empurra o triangulo infraorbital para cima e pode causar rugas no sulco infraorbital ou aprofundar as rugas caso elas já sejam evidentes normalmente.</li>
                <br />
                <li>Aprofunda o sulco nasolabial e levanta a parte superior do sulco.</li>
                <br />
                <li>Levanta e expande as asas do nariz.</li>
                <br />
                <li>Quando a ação é forte os lábios se separam (AU 25).</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '11' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 11</h2>
            <p className='instrucoes__texto'>
              <b>Acentuador do sulco nasolabial</b>
              <br />
              <br />
              O músculo base para as mudanças aparentes do AU 11 não é completamente conhecido. Aparentemente, ele emerge logo abaixo dos ossos da bochecha e vai até perto da porção externa do lábio superior. Porém, como as fibras musculares presentes nessa área não tem uma separação clara, AU 11 pode envolver pequenas ações de fibras que também estão envolvidas no AU 10 ou AU 13.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 11:</b>
              <ol>
              <li>Puxa o lábio superior para cima e lateralmente de maneira moderada.</li>
              <br />
              <li>Puxa a pele embaixo da porção superior do sulco nasolabial de forma obliquá para cima.</li>
              <br />
              <li>Aprofunda a porção medial superior do sulco nasolabial.</li>
              <br />
              <li>Levanta levemente a porção medial superior do triângulo infraorbital.</li>
              <br />
              <li>De forma intensa, pode aprofundar a porção medial superior do sulco infraorbital.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '12' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 12</h2>
            <p className='instrucoes__texto'>
              <b>Levantador do canto do lábio</b>
              <br />
              <br />
              O músculo subjacente do AU 12 emerge no osso da bochecha e termina no canto dos lábios. O movimento AU 12 levanta o canto do lábio na direção do osso da bochecha.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 12:</b>
              <ol>
              <li>Puxa o canto do lábio para trás e para cima de forma obliquá criando uma forma de U invertido na boca (Sorriso).</li>
              <br />
              <li>Aprofunda o sulco nasolabial, puxando ele para cima e para o lado. A pele adjacente ao sulco nasolabial é movida para cima e para o lado.</li>
              <br />
              <li>Em um AU 12 fraca ou moderado, há um certo levantamento do triângulo infraorbital e pode ter um aprofundamento do sulco infraorbital.</li>
              <br />
              <li>Em uma ação mais forte:</li>
              <br />
              <ul>
                <li>O levantamento do triangulo infraorbital é mais evidente.</li>
                <br />
                <li>O aprofundamento do sulco nasolabial é mais evidente.</li>
                <br />
                <li>Acumula pele embaixo da pálpebra inferior.</li>
                <br />
                <li>Estreita a abertura dos olhos empurrando a bochecha e a pele embaixo da pálpebra inferior.</li>
                <br />
                <li>Produz pés de galinha no canto do olho.</li>
                <br />
                <li>Pode levantar e expandir as asas do nariz.</li>
                <br />
                <li>Pode esticar a pele do queixo.</li>
              </ul>
              <br />
              <li>Quando o AU 12 está muito intenso é difícil dizer se o AU 6 está presente. Isso porque o AU 12 esconde muito o efeito do AU 6.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '13' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 13</h2>
            <p className='instrucoes__texto'>
              <b>Levantador do ângulo da boca</b>
              <br />
              <br />
              O músculo subjacente ao AU 13 se origina na frente da porção superior do maxilar, um pouco abaixo do AU 10. E ele corre para baixado e para o meio até o canto da boca. No AU 13, o canto da boca é puxado em um angulo mais brusco para cima na direção da parte superior do osso do maxilar.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 13:</b>
              <ol>
              <li>Faz a bochecha e o triangulo infraorbital se tornarem muito evidentes.</li>
              <br />
              <li>Puxa o canto da boca para cima em um angulo mais fechado do que o AU 12.</li>
              <br />
              <li>Enquanto o canto dos lábios é puxado para cima, a parte vermelha dos lábios não se move para cima com o canto dos lábios.</li>
              <br />
              <li>O canto do lábio parece estar apertado, mais fino e levantado angularmente.</li>
              <br />
              <li>Pode aprofundar  a porção superior e medial do sulco nasolabial.</li>
              <br />
              <li>Pode fazer o lábio superior parecer mais fino.</li>
              <br />
              <li>Em uma ação mais intensa:</li>
              <br />
              <ul>
                <li>Os pés de galinha ficam mais evidentes.</li>
                <br />
                <li>Acumula pele abaixo da pálpebra inferior.</li>
                <br />
                <li>Aprofundamento do sulco nasolabial.</li>
              </ul>
              <br />
              <li>Como o AU 12, o AU13, quando está em uma intensidade muito elevada, mostra sinais na face de forma muito similar ao AU 6.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '14' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 14</h2>
            <p className='instrucoes__texto'>
              <b>Covinha</b>
              <br />
              <br />
              O músculo que realiza o AU 14 é um que se inicia bem atrás do osso da bochecha e corre até a porção central dos lábios. Uma fibra desse músculo se conecta com o lábio superior e o outro se conecta com o lábio inferior. No AU 14, a pele sobre o canto do lábio é puxado para dentro na direção da orelha em certa medida. Diferente do AU 20 que estica os lábios, o AU 14 diminui o canto dos lábios. É comum observar o movimento do AU 14 com movimento dos olhos e da cabeça.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 14:</b>
              <ol>
              <li>Aperta o canto da boca, puxando o canto para dentro e na direção da orelha.</li>
              <br />
              <li>Produz covinhas e/ou protuberância no canto dos lábios.</li>
              <br />
              <li>Pode causar uma covinha no canto do lábio.</li>
              <br />
              <li>O canto do lábio, geralmente, se inclina para cima, mas pode se inclinar para baixo ou não se inclinar.</li>
              <br />
              <li>Os lábios se esticam um pouco lateralmente.</li>
              <br />
              <li>Pode aprofundar o sulco nasolabial.</li>
              <br />
              <li>Puxa a pele embaixo do canto do lábio e a pele do queixo para cima.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '15' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 15</h2>
            <p className='instrucoes__texto'>
              <b>Depressor do canto do lábio</b>
              <br />
              <br />
              O músculo encarregado do AU 15 é um que imerge no lado do queixo e corre para cima até um ponto perto do canto do lábio.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 15:</b>
              <ol>
              <li>Puxa o canto da boca para baixo.</li>
              <br />
              <li>Inclina a canto do baixo para baixo e o lábio inferior fica estendido geralmente.</li>
              <br />
              <li>Gera uma protuberância na pele abaixo do canto do lábio que pode não aparecer se a ação não for intensa.</li>
              <br />
              <li>Pode achatar ou gerar protuberância no queixo. Pode produzir uma depressão média em baixo do lábio inferior.</li>
              <br />
              <li>Se o sulco nasolabial é permanente, ele se aprofunda na porção inferior e pode parecer puxada para baixo ou alongada.</li>
              <br />
              <li>Se o AU 12 aparece juntamente com o AU 15, pode ser dificil dizer se é uma AU 12 + 15 ou AU 6 + 15.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '16' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 16</h2>
            <p className='instrucoes__texto'>
              <b>Depressor do lábio inferior</b>
              <br />
              <br />
              O músculo responsável pelo AU 16 começa dos dois lados do queixo e corre para cima até o lábio inferior. No AU 16, o lábio inferior é puxada na direção do queixo.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 16:</b>
              <ol>
              <li>Puxa o lábio inferior para baixo.</li>
              <br />
              <li>Estica o lábio inferior e puxa ele ligeiramente para o lado.</li>
              <br />
              <li>O lábio inferior pode achatar ou sobressair.</li>
              <br />
              <li>Geralmente, os lábios se abrem (AU 25) com a ação do AU 16, e pode aparecer os dentes e a gengiva inferior. Porém, é possível que o AU 25 não seja acionado com a ação do AU 16.</li>
              <br />
              <li>O queixo é esticado para baixo e para o lado. Achatando a pele sobre o queixo. Podem aparecer rugas sobre a pele do queixo.</li>
              <br />
              <li>Em algumas pessoas, pode aparecer rugas na pele logo abaixo lo lábio inferior.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '17' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 17</h2>
            <p className='instrucoes__texto'>
              <b>Levantador do queixo</b>
              <br />
              <br />
              O músculo causador do AU 17 se inicia em uma área abaixo do lábio inferior e se liga bem abaixo do queixo. No AU 17, a pele do queixo é empurrada para cima, empurrando o lábio inferior para cima.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 17:</b>
              <ol>
              <li>Empurra o queixo para cima.</li>
              <br />
              <li>Empurra o lábio inferior para cima.</li>
              <br />
              <li>Podem aparecer rugas na pele do queixo. E uma depressão mediana pode ser produzida logo abaixo do lábio inferior.</li>
              <br />
              <li>Gera uma forma de U invertido nos lábios, se essa forma já for presente normalmente nos lábios, essa forma vai se intensificar.</li>
              <br />
              <li>Se a ação for intensa, o lábio inferior pode se sobressair.</li>
              <br />
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '18' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 18</h2>
            <p className='instrucoes__texto'>
              <b>Enrugador dos lábios (biquinho)</b>
              <br />
              <br />
              O músculo relevante para o movimento AU 18 se localiza acima e abaixo dos lábios. O AU 18 franze os lábios fazendo com que eles se projetem para frente.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 18:</b>
              <ol>
              <li>Empurra os lábios para frente franzindo eles.</li>
              <br />
              <li>Abrevia a abertura da boca, o que diminui o tamanho da abertura da boca e torna a abertura arredondada.</li>
              <br />
              <li>Os lábios podem protuberar para frente, mas a abreviação pode acontecer sem a protuberância.</li>
              <br />
              <li>A boca fica no mesmo formato que a pessoa pronunciando a vogal ‘o’.</li>
              <br />
              <li>Gera pequenas rugas na pele sobre o lábio superior e pode gerar rugas na pele abaixo do lábio superior e rugas nos lábios em si.</li>
              <br />
              <li>Pode formar pequenas rugas na pele do que queixo.</li>
              <br />
              <li>É possível, mas não comum de o AU 18 afetar somente um lábio. </li>
              <br />
              <li>O AU 18 pode ser unilateral.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '20' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 20</h2>
            <p className='instrucoes__texto'>
              <b>Esticador dos lábios</b>
              <br />
              <br />
              O músculo que realiza o AU 20 surge perto da posterior da mandíbula e é conectado no canto do lábio. A ação do AU 20 puxa os lábios para trás na direção das orelhas. Quando essa ação é forte, geralmente o AU 21 também é recrutado.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 20:</b>
              <ol>
              <li>Puxa os lábios para trás na direção das orelhas. O canto dos lábios pode ser levantado ou abaixado dentro de um certo limite, mas o movimento principal é horizontal.</li>
              <br />
              <li>Alonga a boca.</li>
              <br />
              <li>Os lábios são esticados pela puxada lateral.</li>
              <br />
              <li>Puxa a pele que além do canto dos lábios, a área da bochecha adjacente ao canto do lábio se torna achatada.</li>
              <br />
              <li>Podem aparecer rugas nos cantos dos lábios ou na pele ao lado.</li>
              <br />
              <li>Puxa a porção do sulco nasolabial inferior.</li>
              <br />
              <li>Estica a pele do queixo. Pode causar achatamento no queixo ou/e rugas.</li>
              <br />
              <li>Pode esticar as asas do nariz e alonga a abertura do nariz.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '21' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 21</h2>
            <p className='instrucoes__texto'>
              <b>Tensionador do pescoço</b>
              <br />
              <br />
              O músculo que tensiona o AU 21 é localizado no pescoço, ele corre parte superior do peito até a mandíbula. Esse músculo se mistura com as fibras musculares que estão por trás dos AUs 14, 15. 16. 17 e 20. No AU 21, a mudança mais marcante é no pescoço, mas também são aparentes as mudanças na pele da mandíbula e do queixo.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 21:</b>
              <ol>
              <li>Aparecem rugas e protuberâncias na pele do pescoço e sob o queixo.</li>
              <br />
              <li>Puxa a pele ao redor da borda da mandíbula e do queixo para baixo. Apesar, de essa mudança ser pequena.</li>
              <br />
              <li>Pode deprimir o canto dos lábios, mas, se acontecer, deve ser marcado 15+21.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '22' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 22</h2>
            <p className='instrucoes__texto'>
              <b>Afunilador dos lábios</b>
              <br />
              <br />
              O AU 22 é baseado nas fibras musculares externas que circulam a boca. No AU 22 + 25, a direção do movimento é de apertar a pele ao redor dos lábios.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 22 + 25:</b>
              <ol>
              <li>Os lábios afunilam para fora tomando a forma de como se a pessoa estivesse falando a palavra ‘flirt’.</li>
              <br />
              <li>Puxa o canto dos lábios para a direção central da boca.</li>
              <br />
              <li>Deixa exposto os dentes e pode expor a gengiva. Geralmente, essa exposição acontece mais no lábio inferior do que no superior.</li>
              <br />
              <li>Expõem mais a parte vermelha dos lábios já que os lábios podem virar para fora, geralmente, mais o lábio inferior do que o superior.</li>
              <br />
              <li>Achata ou enruga pouco a região do queixo.</li>
              <br />
              <li>É possível, apesar de não ser comum, do AU 22 afetar somente um lábio.</li>
              <br />
              <li>O AU 22 pode ocorrer somente de uma lado da face.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '23' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 23</h2>
            <p className='instrucoes__texto'>
              <b>Afinador dos lábios</b>
              <br />
              <br />
              Esse AU é baseado primariamente na porção interna do músculo que orbita a boca dentro dos lábios. No AU 23, os lábios e a pele ao redor são apertadas e afinadas.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 23:</b>
              <ol>
              <li>Aperta os lábios fazendo a parte vermelha parecer mais fina.</li>
              <br />
              <li>A parte vermelha dos lábios pode rolar para dentro da boca, escondendo ela da vista.</li>
              <br />
              <li>Se os lábios estiverem separados, pode dar a impressão que há uma pequena protuberância, mas ação é o afinamento dos lábios.</li>
              <br />
              <li>Pode produzir pequenos enrugamentos ou linhas na pele sobre e abaixo da parte vermelha dos lábios e pode aparecer o músculo no lábio inferior protuberando.</li>
              <br />
              <li>Pode produzir um achatamento ou um enrugamento na pele do queixo de maneira bem limitada.</li>
              <br />
              <li>É possível o AU 23 afete somente um lábio, apesar de não ser comum.</li>
              <br />
              <li>O AU 23 pode ocorrer de forma unilateral.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '24' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 24</h2>
            <p className='instrucoes__texto'>
              <b>Pressionador dos lábios</b>
              <br />
              <br />
              Esse AU é baseado na porção interna do músculo orbital da boca dentro dos lábios. No AU 24, os lábios são pressionados um contra o outro.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 24:</b>
              <ol>
              <li>Pressiona os lábios juntos sem envolver o levantamento do queixo. (AU 17).</li>
              <br />
              <li>Abaixa o lábio superior e levanta o lábio inferior em certa medida.</li>
              <br />
              <li>Aperta e afina os lábios. Os lábios aparentam encolhidos devido à pressão do AU 24.</li>
              <br />
              <li>Podem aparecer rugas na pele do lábio superior. </li>
              <br />
              <li>Podem causar protuberâncias na pele acima do lábio superior e/ou abaixo do lábio inferior.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '25' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 25</h2>
            <p className='instrucoes__texto'>
              <b>Separador dos lábios</b>
              <br />
              <br />
              AU 25 especifica o quanto os lábios estão separados.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 25:</b>
              <ol>
              <li>Os lábios perdem o contato e se afastam o que pode expor a parte interna da boca.</li>
              <br />
              <li>Os dentes e a gengiva podem ficar expostos.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '26' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 26</h2>
            <p className='instrucoes__texto'>
              <b>Queda da mandíbula</b>
              <br />
              <br />
              AU 26 representa o quanto a mandíbula caiu quando o músculo perto da mandíbula relaxa. O AU 26 não tem uma intensidade maior de acordo com o quanto está tensionado o músculo, mas, sim, o quanto ele está relaxado. O AU 26 começa a ser marcado com o relaxamento do músculo da mandíbula e com a separação dos dentes.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 25:</b>
              <ol>
              <li>A mandíbula é abaixada por relaxamento e não contração muscular.</li>
              <br />
              <li>Ela começa ser considerada com a separação dos dentes.</li>
              <br />
              <li>A boca abre como se a mandíbula tivesse caído.</li>
              <br />
              <li>O tempo de ativação dessa ação é longa já que ela ocorre por relaxamento e não tensionamento.</li>
              <br />
              <li>É possível ter uma queda da mandíbula sem a abertura dos lábios. Nessa caso, acontece o AU 26 isolado.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '27' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 27</h2>
            <p className='instrucoes__texto'>
              <b>Alongamento da boca</b>
              <br />
              <br />
              AU 27 emede a força usada para abrir e alongar a boca. A diferença do AU 26 do 27 é que o 26 é passivo enquanto o 27 é ativo. O músculo do AU 27 é um antagônico do músculo do AU 26. Nunca se marca o AU 27 junto com o 26.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 27:</b>
              <ol>
              <li>A mandíbula é puxada para baixo.</li>
              <br />
              <li>Pode abrir a bastante a boca.</li>
              <br />
              <li>A boca abre não por um relaxamento do músculo, mas por uma tensão ativa de um músculo que puxa a mandíbula para baixo.</li>
              <br />
              <li>Os lábios podem estar esticados dependendo da intensidade da ação.</li>
              <br />
              <li>Achata ou enruga o queixo.</li>
              <br />
              <li>É possível o AU 27 sem a separação dos lábios.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '28' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 28</h2>
            <p className='instrucoes__texto'>
              <b>Sucção dos lábios</b>
              <br />
              <br />
              Esse AU envolve o músculo orbicular que circula a boca e os lábios. No AU 28, os lábios são puxados para dentro da boca. Esse movimento pode envolver somente o lábio superior ou inferior.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 28:</b>
              <ol>
              <li>Suga a parte vermelha do lábio e a pele perto da boca para dentro dela cobrindo os dentes.</li>
              <br />
              <li>A parte avermelhada dos lábios não fica visível.</li>
              <br />
              <li>Estica a pele acima e abaixo dos lábios conforme eles são puxados para dentro da boca.</li>
              <br />
              <li>Achatamento da pele do queixo.</li>
              <br />
              <li>Pode causar enrugamento ou protuberâncias nos cantos do lábio.</li>
              <br />
              <li>O AU 28 pode ser ativo em somente um lábio.</li>
              <br />
              <li>Ele pode ser unilateral.</li>
              <br />
              <li>Geralmente, quando o 28 for acionado, o 26 também estará presente.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '29' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 29</h2>
            <p className='instrucoes__texto'>
              <b>Impulsionador da mandíbula</b>
              <br />
              <br />
              O AD 29 controla o impulsionamento da mandíbula para frente.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 28:</b>
              <ol>
              <li>A mandíbula inteira é impulsionada para frente.</li>
              <br />
              <li>O queixo parece se destacar.</li>
              <br />
              <li>Os dentes de baixo se sobressaem em relação aos de cima.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '41' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 41</h2>
            <p className='instrucoes__texto'>
              <b>Queda das pálpebras</b>
              <br />
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '43' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 43</h2>
            <p className='instrucoes__texto'>
              <b>Fechamento dos olhos</b>
              <br />
              <br />
              AU 43 é o fechamento dos olhos. Esse AU quando acontece em intensidade máxima. O mesmo músculo responsável pelo AU 5, quando ele está relaxado totalmente ocorre o fechamento dos olhos (AU 43). Essa ação é de relaxamento e não de tensão muscular. Se as pálpebras fecharem o olho pela metade, o AU 43 estará presente, porém em uma intensidade menor. A intensidade do AU 43 será comparada com a abertura padrão das pálpebras da pessoa.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 43:</b>
              <ol>
              <li>A pálpebra superior cai reduzindo a abertura do olho.</li>
              <br />
              <li>Será exposto uma superfície maior da pálpebra superior do que o normal. Algumas pessoas já tem uma superfície grande exposta por padrão em comparação com as outras pessoas, nesse caso, se marca a intensidade do AU 43 conforme a comparação com o padrão dela.</li>
              <br />
              <li>Quando os olhos estão completamente fechados, o AU 43 está na sua intensidade máxima, porém não há tensionamento de músculos na região dos olhos.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '45' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 45</h2>
            <p className='instrucoes__texto'>
              <b>Piscar</b>
              <br />
              <br />
              Piscar é o movimento rápido de fechar e abrir os olhos. AUs relevantes nesse movimento são os 5, 7 e 43. Pisar envolve o relaxamento e a contração do músculo responsável pelo AU 5.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 45:</b>
              <ol>
              <li>Ocorre um movimento rápido dos olhos em abrir e fechar sem hesitação quando os olhos estão fechados.</li>
              <br />
              <li>Os olhos não devem estar fechados mais do que ½ de segundo em uma piscada. Esse AU não pode ser reconhecido em uma fotografia. </li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else if(props.estadoDaEmocao === '46' && props.estadoDoConteudoPremium) {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
          <div className='instrucoes__caixa-de-texto'>
            <h2 className='instrucoes__titulo'>AU: 42</h2>
            <p className='instrucoes__texto'>
              <b>Piscadela</b>
              <br />
              <br />
              O músculo envolvido nesse AU é o mesmo que o 45 na mesma sequência. A diferença é que o fechamento da pálpebra superior é mais lento e há uma pausa enquanto os olhos estão fechados e é unilateral.
              <br />
              <br />
              <br />
              <b>Mudanças aparentes devido ao AU 46:</b>
              <ol>
              <li>Um olho fecha de forma breve com uma pausa maior do que uma piscada. O fechamento do olho deve ser unilateral e deve ter uma pausa deliberada.</li>
              <br />
              <li>O fechamento do olho deve ser mais breve do que 2 segundos. Esse AU não pode ser detectado em fotos.</li>
              </ol>
              <br />
              <p><i>Fonte: FACS Manual - Paul Ekman</i></p>
            </p>
          </div>
        </section>
      );
    }else {
      return(
        <section className='instrucoes'>
          <div className='instrucoes__opcoes'>
            <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
            <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
            {estadoDoEspecifico}
          </div>
        </section>
      );
    }
  }else if(opcaoEscolida === 'introducao') {
    return(
      <section className='instrucoes'>
        <div className='instrucoes__opcoes'>
          <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
          <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
          <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
          {estadoDoEspecifico}
        </div>
        <div className='instrucoes__caixa-de-texto'>
          <h2 className='instrucoes__titulo'>Entenda a ferramenta</h2>
          <p className='instrucoes__texto'>
            Você já viu um vídeo que analisa se a pessoa está mentindo e quis entender como funciona? Já quis descobrir qual emoção os outros estão sentindo somente olhando o rosto delas? Se você respondeu sim para alguma das perguntas, essa ferramenta foi feita pensando em ajudar você! Aqui você vai aprender as mesmas técnicas utilizadas pela CIA, FBI, Apple e Harvard para desvendar as emoções por meio das Expressões Faciais.
            <br />
            <br />
            Quem já tentou aprender sobre as Expressões Faciais já deve ter se deparado com uma série de obstáculos, como o preço elevado dos cursos que ensinam esse assunto, a falta de material em português(BR) e a complexidade do matérial sobre o assunto. Essa ferramenta foi desenvolvida para derrubar todas essas barreiras. Essa ferramenta busca explicar de maneira simples como as Expressões Faciais funcionam e busca te armar com uma habilidade poderosíssima que é o reconhecimento de emoções, tudo isso baseado em ciência e pesquisa.
            <br />
            <br />
            <br />
            <b>Por que aprender as Expressões Faciais?</b>
            <br />
            <br />
            Provavelmente, todo mundo já se deparou com o caso de um colega seu de trabalho receber um aumento, não porque ele trabalhou mais ou melhor, mas sim porque ele tinha um bom relacionamento com o chefe. Isso se dá pelo fato que grande parte do sucesso profissional é causado pelas habilidades sociais.
            <br />
            <br />
            Como mostra o Dale Carnegie no seu Best Seller “Como fazer amigos e influenciar pessoas” por meio da frase “15% do sucesso financeiro é devido a conhecimento técnico e 85% é devido a habilidades de engenharia humana (habilidades sociais) e a habilidade de liderar pessoas”.
            <br />
            <br />
            Muitas pessoas podem ter entendido a importância das habilidades sociais, mas talvez elas estejam se perguntando “Será que reconhecer as expressões faciais e as emoções é uma habilidade social relevante?”. Como mostra o artigo “Beyond social capital: How social skills can enhance entrepreneurs' success" - Robert A. Baron and Gideon D. Markman, a habilidade social mais importante é a capacidade de reconhecer as emoções e as motivações dos outros e é exatamente o propósito dessa ferramenta.
            <br />
            <br />
            Ou seja, aqui você vai aprender a habilidade social mais importante, responsável por 85% do sucesso financeiro e profissional de forma simples. Então teste agora!
          </p>
        </div>
      </section>
    );
  }else if(opcaoEscolida === 'emocoes') {
    return(
      <section className='instrucoes'>
        <div className='instrucoes__opcoes'>
          <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
          <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
          <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
          {estadoDoEspecifico}
        </div>
        <div className='instrucoes__caixa-de-texto'>
          <h2 className='instrucoes__titulo'>Como funcionam as emoções</h2>
          <p className='instrucoes__texto'>
            As emoções estão presentes quase todo momento em nosso dia a dia e são determinantes nas nossas tomadas de decisões, nas nossas interpretações do ambiente e na nossa qualidade de vida, no entanto, as pessoas em geral têm pouco conhecimento sobre como elas funcionam. Ter tal conhecimento é importante para que a pessoa consiga reconhecer as emoções em si mesmo e nos outros, e para que ela possa tomar decisões mais assertivas.
            <br />
            <br />
            <br />
            <b>O que são emoções?</b>
            <br />
            <br />
            O estudo das emoções no meio da psicologia é algo recente e ainda há muitas discordâncias sobre o tema. Porém, conforme o psicologo americano Paul Ekman, as emoções são reações fisiológicas involuntárias que preparam o indivíduo para tomar uma ação.
            <br />
            <br />
            Por exemplo, caso você esteja com raiva e perceba que está em uma luta iminente, o seu sistema nervoso autônomo vai enviar sangue para os seus braços, vai haver um aumento da carga cognitiva, a sua pupila vai dilatar, a sua face vai fazer uma expressão carrancuda, entre outras reações. Essas repostas do organismo aumentam a chance do indivíduo ter sucesso no seu confronto, e esse ideia se manifesta nas outras emoções também.
            <br />
            <br />
            Há muita discordância se as emoções são universais, isto é, se as emoções são iguais em todas as pessoas ou se elas variam conforme a cultura. Paul Ekman, que já foi um defensor da hipótese que as emoções variam com a cultura, provou que as emoções são universais na sua tentativa de provar o contrário.
            <br />
            <br />
            <br />
            <b>Como as emoções são acionadas?</b>
            <br />
            <br />
            Houve muitas dúvidas entre os psicólogos sobre o que desencadeia as emoções, porém há diversas pesquisas que chegaram a uma conclusão bem sólida sobre esse tema. Os psicólogos Jerry Boucher e Klaus Scherer, observaram que os gatilhos que geravam emoções eram iguais em diferentes culturas, isto é, os mesmos eventos geravam as mesmas emoções independente do arcabouço cultural dos participantes. No entanto, como mostra Paul Ekman, existem gatilhos que são compartilhados por todos e gatilhos individuais.
            <br />
            <br />
            De forma simplificada, as emoções acontecem quando o indivíduo interpreta, na sua memória associativa (cérebro), um certo padrão de pensamento. Por exemplo, quando o indivíduo percebe uma ameaça imediata ou iminente, ele vai sentir medo. Se ele perceber que teve uma perda significativa no passado, ele sentirá tristeza e gatilhos diferentes iram gerar emoções diferentes. Se quiser conhecer o gatilho de cada emoção, clique no botão das emoções, lá explicarei com mais detalhes.
            <br />
            <br />
            <br />
            <b>Quais são as 7 emoções universais?</b>
            <br />
            <br />
            As emoções que foram comprovadamente declaradas como universais, ou seja, se manifestam igual em todas as pessoas são alegria, tristeza, raiva, nojo, desprezo, medo e surpresa. Mas existem outras emoções que também podem se tornar universais, mas ainda não foram provadas, elas são constrangimento, culpa, vergonha e inveja de acordo com Paul Ekman.
            <br />
            <br />
            <br />
            <b>Como as emoções variam de intensidade?</b>
            <br />
            <br />
            Por regra, quanto maior for o risco-ganho percebido pela pessoa, mais intensa será a emoção que ela vai vivenciar. Uma forma clara de verificar isso, é observar que o medo que você vai sentir pela ameça de ser demitido vai ser menor do que a ameaça de ser morto por um animal selvagem. Isso acontece decorrente de você perceber que o segundo caso vai te trazer maior dor e maior perda do que o primeiro caso. Resumindo, quando mais ‘valor’ estiver em jogo, mais intensa será a emoção.
            <br />
            <br />
            <br />
            <b>As emoções não são privadas</b>
            <br />
            <br />
            Quando estamos passando por um episódio emocional, o nosso corpo comunica vários sinais mostrando que estamos vivenciando tal emoção. Esses sinais são expressos de diversas formas pelo corpo, entretanto a forma predominante é as expressões faciais. Por meio delas, podemos reconhecer de forma assertiva a emoção que a pessoa está sentindo naquele momento. Cada uma das 7 emoções, tem o seu conjunto de movimentos musculares que denunciam a emoção que está sendo vivenciada.
            <br />
            <br />
            Essas expressões podem acontecer de 3 formas, macroexpressões, microexpressões e expressões súbitas. As macroexpressões podem durar até 3 segundos, e são expressas na face de maneira uniforme. As microexpressões duram de ¼ a ⅕ de segundos. Elas acontecem quando a pessoa está tentando ocultar a emoção de forma deliberada pelo seu neocortex contra os impulsos involuntários do sistema nervoso autônomo ou no início de um episódio emocional.
            <br />
            <br />
            Já a expressão súbita acontece quando a pessoa está tentando ocultar a emoção, quando a emoção acontece em uma intensidade baixa ou quando é o inicio de um episódio emocional. Essa expressão acontece somente em uma parte da face e igual a microexpressão, tem uma duração de menos de 1 segundo.
            </p>
        </div>
      </section>
    );
  }else if(opcaoEscolida === 'aus') {
    return(
      <section className='instrucoes'>
        <div className='instrucoes__opcoes'>
          <OpcoesDeInstrucoes className='instrucoes__introducao' colherOpcao={mudarOpcao} />
          <OpcoesDeInstrucoes className='instrucoes__emocoes' colherOpcao={mudarOpcao} />
          <OpcoesDeInstrucoes className='instrucoes__aus' colherOpcao={mudarOpcao} />
          {estadoDoEspecifico}
        </div>
        <div className='instrucoes__caixa-de-texto'>
          <h2 className='instrucoes__titulo'>O que são AUs?</h2>
          <p className='instrucoes__texto'>
          <b>AUs (Actions Units)</b> são unidades de ação dos músculos faciais. Cada AU representa um movimento facial específico. Dependendo do conjunto de AUs que a pessoa expressa, é possível reconhecer a emoção que ela está sentindo. Os AUs podem variar de intensidade entre A, B, C, D e E. Quando maior for a intensidade, maior será a letra do alfabeto.
          </p>
        </div>
      </section>
    );
  }
}

export default Instrucoes;
