import firebase from 'firebase/app';
import 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';

import './AreaDeCompra.css'

var db = firebase.firestore();
const analytics = firebase.analytics();

const AreaDeCompra = (props) => {

  const botaoDeCompraApertado = async () => {
    analytics.logEvent('click_buy_button');
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const subscribe = async() => {
          const docRef = await db
          .collection('customers')
          .doc(user.uid)
          .collection('checkout_sessions')
          .add({
            price: 'price_1J6QXRI6pYNUcMpACdQKRhGz',
            success_url: window.location.origin + '/face-emotions/action',
            cancel_url: window.location.origin+ '/face-emotions/',
          });
          docRef.onSnapshot(async (snap) => {
            const { error, sessionId } = snap.data();
            if (error) {
              alert(`An error occured: ${error.message}`);
            }
            if (sessionId) {
              const stripe = await loadStripe('pk_live_51J4SwXI6pYNUcMpAl5oMtBk72YdytZhjEXtWzsQJCvRFSqduwxzSLNH8KdUCit8vMFoir1SO38GAK6Qka8oK3um400pOzCP5E2');
              stripe.redirectToCheckout({ sessionId });
            }
          })
        }
        subscribe();
        props.passarLoading()
        analytics.logEvent('load_checkout');
        var uid = user.uid;
      }else {
        props.colherEstadoDaCaixaDeCadastro(true)
      }
    });
  }

  return(
    <div className='area-de-compra'>
      <div className='area-de-compra__centro'>
        <h2 className='area-de-compra__titulo'>Acesse os <b className='area-de-compra__aus'>30 principais AUs</b> por somente <span className='area-de-compra__preco'>R$25,90/mês</span></h2>
        <a className='area-de-compra__botao' onClick={botaoDeCompraApertado}>Acessar</a>
      </div>
    </div>
  )
}

export default AreaDeCompra;
