import './Promocao.css';

const Promocao = () => {
  return(
    <section className="promocao">
      <h2 className="promocao__titule">Porque utilizar Reconhecimento de Expressões Faciais</h2>
      <div className="promocao__caixa">
        <div className="promocao__caixa-1">
          <div className="promocao__caixa-1__icon" />
          <div className="promocao__caixa-1__titule">RECONHECER EMOÇÕES</div>
          <div className="promocao__caixa-1__sub-titule">Consiga reconhecer as emoções que os outros estão tentando esconder somente vendo a face.</div>
        </div>
        <div className="promocao__caixa-2">
          <div className="promocao__caixa-2__icon" />
          <div className="promocao__caixa-2__titule">IDENTIFICAR INTEÇÕES</div>
          <div className="promocao__caixa-2__sub-titule">Esteja à frente e descobra quais são as intensões dos outros antes deles agirem.</div>
        </div>
        <div className="promocao__caixa-3">
          <div className="promocao__caixa-3__icon" />
          <div className="promocao__caixa-3__titule">DETECTAR MENTIRAS</div>
          <div className="promocao__caixa-3__sub-titule">Tenha a habilidade de reconhecer quando os outros estão mentindo pela face.</div>
        </div>
        <div className="promocao__caixa-4">
          <div className="promocao__caixa-4__icon" />
          <div className="promocao__caixa-4__titule">MELHORAR HABILIDADES SOCIAIS</div>
          <div className="promocao__caixa-4__sub-titule">Desenvolva a habilidade social mais importante para o sucesso profissional e financeiro.</div>
        </div>
      </div>
    </section>
  )
}

export default Promocao;
