import './MarcadorDeAu.css';
import { useSpring, animated } from 'react-spring';

const MarcadorDeAu = (props) => {
  const animacaoDoPonto = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: true,
  })

  const animacaoDaLinha = useSpring({
    from: { x: 55 },
    to: { x: 0 },
    reset: true,
    config: {duration: 200}
  })

  let origemDaAnimacao = ''
  if(props.tipoDoMarcador === 'baixo-esquerda-1') {
    origemDaAnimacao = '0% 100%'
  }else if(props.tipoDoMarcador === 'baixo-direita-1') {
    origemDaAnimacao = '100% 100%'
  }else if(props.tipoDoMarcador === 'alto-direita-1') {
    origemDaAnimacao = '100% 0%'
  }else if(props.tipoDoMarcador === 'alto-esquerda-1') {
    origemDaAnimacao = '0% 0%'
  }

  const animacaoDaCaixa = useSpring({
    from: {
      transform: 'scale(0)',
      transformOrigin: origemDaAnimacao,
    },
    to: {
      transform: 'scale(1)',
      transformOrigin: origemDaAnimacao,
    },
    reset: true,
  })

  const tipoDoMarcadorCaixa = 'caixa__' + props.tipoDoMarcador;
  const tipoDoMarcadorLinha = 'linha__' + props.tipoDoMarcador;
  const tipoDoMarcadorBarra = 'barra__' + props.tipoDoMarcador;
  const tipoDoMarcadorPoint = 'point__' + props.tipoDoMarcador;
  const nomeDaClasse = 'marcador-de-au ' + props.className
  const levarParaAus = () => {
    props.levarInformacaoAteAus(props.au);
  }

  return(
    <div className={nomeDaClasse}>
      <animated.div className={'marcador-de-au__caixa ' + tipoDoMarcadorCaixa} onClick={levarParaAus} style={animacaoDaCaixa}>
        <p className='marcador-de-au__texto'>AU: {props.au}</p>
      </animated.div>
      <div className={'marcador-de-au__barra ' + tipoDoMarcadorBarra}>
        <animated.div className={'marcador-de-au__linha ' + tipoDoMarcadorLinha} style={animacaoDaLinha}></animated.div>
      </div>
      <animated.div className={'marcador-de-au__point ' + tipoDoMarcadorPoint} style={animacaoDoPonto}></animated.div>
    </div>
  )
}

export default MarcadorDeAu;
