import './TextoVendas.css';

const TextoVendas = () => {
  return(
    <div className='texto-vendas'>
      <h2 className='texto-vendas__texto'><i>"Você nunca foi ensinado a falar com o rosto como você fala com as palavras." - Paul Ekman</i></h2>
    </div>
  )
}

export default TextoVendas;
