import { useSpring, animated } from 'react-spring';
import './AusBar.css';

const AusBar = (props) => {
  const animacaoDaBarraDosAus = useSpring({
    from: { rotateY: 90 },
    to: { rotateY: 0 },
    reset: true,
  })

  let aus = ''
  if(props.estadoDaEmocao === 'alegria') {
    aus = 'AUs: 6 + 12';
  }else if(props.estadoDaEmocao === 'tristeza') {
    aus = 'AUs: 1 + 4 + 15 + 17 + 41';
  }else if(props.estadoDaEmocao === 'raiva') {
    aus = 'AUs: 4 + 5 + 7 + 23 + 29';
  }else if(props.estadoDaEmocao === 'nojo') {
    aus = 'AUs: 9 + 17';
  }else if(props.estadoDaEmocao === 'desprezo') {
    aus = 'AUs: 14';
  }else if(props.estadoDaEmocao === 'medo') {
    aus = 'AUs: 1 + 2 + 4 + 5 + 20';
  }else if(props.estadoDaEmocao === 'surpresa') {
    aus = 'AUs: 1 + 2 + 5B + 25 + 26';
  }

  return(
    <animated.div className='aus-bar' style={animacaoDaBarraDosAus}>
      <p className='aus-bar__text'>{aus}</p>
    </animated.div>
  )
}

export default AusBar;
