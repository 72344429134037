import './TermosDeUso.css';

const TermosDeUso = (props) => {
  const passarEstadoDoTermosDeUso = () => {
    props.colherEstadoDoTermosDeUso()
    window.history.pushState("object or string", "Title", "/")
  }

  return(
  <>
    <div className='termos-de-uso__exit' onClick={passarEstadoDoTermosDeUso}>
      <div className='termos-de-uso__exit-1'></div>
      <div className='termos-de-uso__exit-2'></div>
    </div>
    <div className='termos-de-uso'>
      <h2><strong>Termos e condições de uso do site ou blog</strong></h2>
      <p>Bem-vindo(a) ao nosso website. Os serviços e/ou produtos são oferecidos pela Facing Tech na forma deste termo representada apenas por site, blog ou empresa, responsável e titular da propriedade intelectual, com o endereço https://facingtech.com.br e com todos os direitos reservados sobre os artigos, vídeos e imagens presentes e relacionados no mesmo.</p> <p>A permanência no website implica-se automaticamente na aceitação tácita do presente termos de uso a seguir.</p>
      <h3>1. <strong>Do Objeto</strong></h3>
      <p>Essa plataforma tem como finalidade o uso do website com os conteúdos de propriedade intelectual da empresa, com diferentes propósitos, sempre voltado para informação e conhecimento do usuário. A Empresa proporciona informação e conteúdo de alta qualidade, feito por equipe profissional e revisado por especialistas, com função apenas informativa.</p>
      <p>Por meio dessa plataforma também pode ser realizado e-commerce, ou seja, a venda por meio eletrônico de produtos e serviços<strong>.</strong> Você também terá acesso a conteúdo original, vídeos e artigo feito pela nossa equipe, que proporciona a solução de dúvidas e questionamentos do leitor.</p>
      <p>Destaca-se que não se responsabilizamos pelo mau uso destas informações, e que nossa empresa fará o possível para manter todos os dados atualizados, porém pode algum artigo conter alguma informação que não reflete a verdade no presente momento.</p>
      <h3>2. <strong>Da aceitação</strong></h3>
      <p>O presente documento “Termos e Condições de Uso” aplicáveis ao uso do site/blog, criado pelo Advogado <a href="https://diegocastro.adv.br/"> Diego Castro</a> e modificado para uso neste site, especifica que o usuário antes de acessar o site leia bem e revise os termos presentes, pois estabelece obrigações contratadas de livre e espontânea vontade por tempo indeterminado entre todos os usuários ou visitantes do site, ou blog.</p>
      <p>Ao utilizar ou acessar a plataforma o usuário aceita e se compromete com as regras estabelecidas de maneira integral, sob o risco de ser penalizado criminalmente. A aceitação do presente instrumento é imprescindível para o acesso à página. Caso não concorde com os termos e condições de uso que constam no decorrer desse documento, por favor não acesse ou use site, ou blog.</p>
      <p>O Site e subsidiários se reservam ao direito de atualização dos termos de uso periodicamente, sem qualquer aviso, podendo assim, esses sofrer atualizações ou modificações.</p>
      <h3><strong>3. Do acesso dos usuários</strong></h3>
      <p>Em alguns dias ou horários o acesso à página poderá vir a ser interrompido, limitada ou suspensa, devido a modificações, atualizações ou ações semelhantes que sejam necessárias para melhorar o desempenho e acesso aos usuários e ao proprietário.</p>
      <p>O site e qualquer serviço oferecido pela página só poderão ser disponibilizados a você caso concorde integralmente com a legislação aplicável e com este termo de uso.</p>
      <p>Menores de 18 anos ou aqueles que não possuírem plena capacidade civil devem obter o prévio consentimento expresso dos responsáveis para ter acesso à página, sendo responsabilidade exclusiva dos mesmos, o acesso ao conteúdo da página por menores de 18 anos ou por aqueles que não tenham capacidade civil. </p>
      <p>Se você for menor de 18 anos não acesse o site sem autorização do seu responsável. Preste bastante atenção, ao utilizar/acessar esse site você declara que tem capacidade jurídica plena, ou seja, declara ser maior de 18 anos e ser capaz civilmente. </p>
      <h3>4. <strong>Do Cadastro </strong></h3>
      <p>Para você ter acesso à página/funcionalidades/serviços pode ser necessário a realização de um cadastro prévio.</p>
      <p>Ao se cadastrar o usuário deverá informar corretamente os dados completos, sendo sua a exclusiva responsabilidade de atualiza-los, bem como o seu comprometimento que os dados apresentados tem veracidade.</p>
      <p>O usuário do site/blog ainda se compromete a não informar a qualquer pessoa/terceiros os seus dados cadastrais que contém na plataforma e se responsabiliza pelo uso e pelo que seja feito. Em alguns casos, após a confirmação cadastral, você terá um login (usuário e senha) e assim poderá acessar a página. O acesso é individual.</p>
      <p>Qualquer atividade utilizada com o seu login é de sua total responsabilidade. Em caso de uso indevido da senha ou perda informe rapidamente a plataforma. A sua conta é intransferível. Não é licito vender, ceder, alugar ou emprestar.</p>
      <h3>5. <strong>Licença</strong></h3>
      <p>A EMPRESA concede uma licença limitada, intransferível, revogável, não exclusiva e não passível de qual estão autorizando a visualizar os materiais exibidos no site ou blog, exclusivamente para uso pessoal sem fins lucrativos. </p>
      <p>Todos os materiais encontrados no site, incluindo, mas não se limitando, aos sons, vídeos, áudios, logotipos, nomes comerciais, disposições, ilustrações, fotografias, obras de artes, código de HTML, design da página e software são de propriedade da EMPRESA<strong> </strong>e seus fornecedores de conteúdo, tendo esses reservados o direito em relação à propriedade intelectual e proibido sua cópia sem prévia autorização expressa.</p>
      <p>Os materiais contidos nessa página são resguardados pela <a href="http://www.planalto.gov.br/ccivil_03/leis/l9610.htm">Lei 9.610/18- Lei dos Direitos Autorais,</a> Lei nº 9.279/96- Lei da Propriedade Industrial e outras regras, regulamentos pertinentes e legislações nacionais ou internacionais de propriedade intelectual aplicáveis.</p>
      <p>Esses termos de uso não cedem ou transferem ao usuário do site qualquer direito a utilização da propriedade intelectual, a não ser a concedida para uso pessoal ou a não ser a concedida com a licença limitada que foi adquirida.</p>
      <p>O usuário não poderá usar qualquer componente da página sem o consentimento prévio e expresso do proprietário e respectivos titulares. Os usos indevidos da página ou dos conteúdos contidos no mesmo violam os direitos de propriedade intelectual da EMPRESA, e são puníveis com os termos da legislação aplicável.</p>
      <h3>6. <strong>Proibições Gerais</strong></h3>
      <p>Em relação ao seu USO, você concorda em NÃO:</p>
      <p>1 - Violar quaisquer direitos relacionados a privacidade ou propriedade intelectual, incluindo, sem qualquer limitação, patentes, marcas, direitos autorais, segredos comerciais de terceiros, incluindo a Facing Tech e seus sócios/afiliados.</p>
      <p>2 - Violar qualquer uma das regras, legislações ou regulamentos aplicáveis, legislações referentes a exportação, leis contra a discriminação, leis que violem os direitos autorais.</p>
      <p>3 - Publicar, armazenar, carregar, copiar qualquer material que:</p>
      <p>A) É considerado ilegal, difamatório, ofensivo, enganoso, danoso, ameaçador, fraudulento, constrangedor ou obsceno;</p>
      <p>B) Interfira de qualquer maneira nas operações e utilização normal do site, como publicar ou transmitir arquivos de vírus, spams, cartas de correntes, franquias ou algo semelhante;</p>
      <p>C) Violar qualquer obrigação contratual;</p>
      <p>D) Violar a confidencialidade;</p>
      <p>E) Constranger ou perseguir terceiro com algum tipo de mensagem ou algo semelhante;</p>
      <p>F) Violar ou tentar violar medidas de segurança do Site</p>
      <p>G) Acessar ou utilizar conta de terceiro no site;</p>
      <p>H) Utilizar robôs ou sistemas de varredura para monitorar ou acessar arquivos do site;</p>
      <p>I) Modificar, copiar, reproduzir, distribuir, apagar, armazenar, transmitir, vender, revender, publicar e quaisquer termos afins, dos materiais e conteúdos presentes no site/blog, exceto com autorização ou que sejam de sua propriedade;</p>
      <p>J) Fingir ser outra pessoa/terceiro;</p>
      <p>K) Publicar qualquer informação considerada falta, incompleta ou imprecisa;</p>
      <p>L) Fornecer quaisquer informações falsas sobre você;</p>
      <p>M)Adulterar qualquer informação, como mensagem de e-mail ou cabeçalho com a logo.</p>
      <h3>7. <strong>Das Sanções</strong></h3>
      <p>Sem nenhum prejuízo das demais medidas legalmente cabíveis, a empresa poderá a qualquer momento suspender, cancelar ou advertir o acesso/conta do usuário, sem qualquer aviso e sem notificação, quando o mesmo fizer algo que:</p> <p>a) Que viole os termos presentes nesse documento;</p>
      <p>b) Que não respeite e descumpra os deveres de usuário;</p>
      <p>c) Que tiver qualquer comportamento fraudulento, de ofensa ou doloso em relação a terceiros.</p>
      <h3>8. <strong>Responsabilidades e usos específicos</strong></h3>
      <p>Além das responsabilidades previstas nos termos e condições de uso, você concorda em cumprir as seguintes cláusulas:</p>
      <ul>
        <li>Utilizar o site apenas para finalidades devidas e legitimas, sem qualquer desvirtualização da principal finalidade;</li>
        <li>Fornecer informações completas, atualizadas, precisas e verdadeiras;</li>
        <li>Publicar materiais ou comentários que você tenha total responsabilidade, direitos e licenças de uso;</li>
        <li>Assumir riscos de qualquer utilização indevida do site ou dos conteúdos nele disponibilizados;</li>
        <li>Informações divulgadas pelo usuário, seja por comentário ou de qualquer outra forma;</li>
        <li>Proteção pelos seus dados de login;</li>
        <li>Conteúdo ou atividades ilícitas praticadas por meio dessa página;</li>
        <li>É terminantemente proibido conteúdo de páginas externas, links para fins comerciais ou publicitários, informações ilícitas, vexatórias, violentas, polemicas, xenofóbicas, pornográficas, ofensivas e discriminatórias.</li>
      </ul>
      <p>O site ou blog também pode vir a apresentar links como botões de hyperlinks, que direciona o acesso a sites de terceiros.</p>
      <p>Os sites/blogs de terceiros não tem nenhuma ligação com essa página, não sendo o proprietário (pessoa física ou jurídica) responsável por qualquer informação, material ou conteúdo que contenha em sites ligados e não sendo responsáveis por nenhum conteúdo ou produto a venda em outros site.</p>
      <p>O site/ blog ou qualquer outra plataforma não tem nenhuma associação, ou relacionamento, e muito menos alguma forma de patrocínio com o proprietário dessa página.</p>
      <p>A responsabilidade pelo acesso de sites que aparecem na página é exclusiva de sua responsabilidade.</p>
      <p>Utilize bem o seu discernimento e senso comum para acessar sites de terceiros que apareçam nos links e sempre confiram os termos de uso e políticas de privacidade. Não nos responsabilizamos por políticas de privacidade de terceiros, mesmo que sejam sites/ blogs de links que apareceram em nossa plataforma.</p>
      <h3><strong>9. Da Política de Privacidade e dos Cookies</strong></h3>
      <p>Além do presente documento, o usuário deverá concordar com as disposições contidas na Política de Privacidade estabelecida pela plataforma e, neste ato, autoriza a empresa a<strong> </strong>coletar, armazenar, divulgar, utilizar, compartilhar com parceiros seus dados pessoais conforme o previsto em tal arquivo.</p>
      <p>Um cookie é um pequeno arquivo de texto, que quando o usuário acessa o site ou blog, é solicitado em seu dispositivo para que o navegador lembre informações sobre o mesmo, como nome de usuário e informações de logins.</p>
      <p>Os cookies são definidos por nossa equipe, podendo ser cookies de domínio do site, publicidade ou marketing.</p>
      <p>Utilizamos os cookies para fins de publicidade, redes sociais, desempenho ou estritamente necessários.</p>
      <p><em>Ex: Cookies de publicidade: Esse tipo de cookie pode ser estabelecido pelo site/blog ou parceiros relacionados a publicidades para construir um perfil sobre os interesses dos usuários e assim mostrar assuntos considerados relevantes.</em></p>
      <p>Caso não permita o acesso aos cookies tem menos acesso a publicidade direcionada.</p>
      <h3>10. <strong>Monetização</strong></h3>
      <p>O site/blog é ou pode ser monetizado por diferentes forma, incluindo-se, mas não se limitando a parceria diretas com empresas, ou plataformas de anúncios como o Google Ads (Adsense) ou similares.</p>
      <p>Estes programas utilizam cookies para personalizar os anúncios aos visitantes e podem utilizar dados de navegação para oferecer melhores anúncios para o visitante.</p>
      <p>Qualquer post patrocinado é sinalizado com o marcador “Publicidade”</p>
      <p>Se deseja ter o seu produto anunciado em nosso blog/site ou anunciar o seu produto, ou serviço, entre em contato.</p>
      <p>A Empresa não responsabiliza por compras ou acesso a site de terceiros que por ventura venham a aparecer nos anúncios, já que os mesmos podem ser feito de forma automática.</p>
      <h3>11. <strong>Do Foro</strong></h3>
      <p>Em caso de eventuais litígios decorrentes de qualquer controvérsia será aplicado o Direito brasileiro e a legislação nacional aplicável, onde o foro elegido será o da comarca da Empresa. </p>
    </div>
  </>
  )
}

export default TermosDeUso;
