import './Heading.css';
import logo from './../images/facing-tech.png'
import { useState, useEffect, useContext } from 'react';
import firebase from "firebase";
import FirebaseStart from './../../../context/firebase-start';
import LanguageContext from './../../../context/language-context';

const db = firebase.firestore();

const Heading = () => {
  // const [firestoreHeading, setFirestoreHeading] = useState('')
  // const ctxFire = useContext(FirebaseStart);
  // const ctx = useContext(LanguageContext);
  //
  // ctxFire.firebaseStart()
  //
  // const captarDados = () => {
  //   let firestoreData = db
  //                   .collection('translations')
  //                   .doc('Heading')
  //                   .get()
  //                   .then((doc) => {
  //                     return doc.data()
  //                   })
  //   var fs = Promise.resolve(firestoreData);
  //   fs.then((data) => {
  //     setFirestoreHeading(data)
  //   })
  // }
  //
  // let titules = '';
  // let subTitules = '';
  // let buttons = '';
  //
  // let titulesBR = '';
  // let subTitulesBR = '';
  // let buttonsBR = '';
  //
  // try {
  //   titules = firestoreHeading.translated.titule;
  //   subTitules = firestoreHeading.translated.subtitule;
  //   buttons = firestoreHeading.translated.button;
  //
  //   titules.pt = firestoreHeading.input.titule;
  //   subTitules.pt = firestoreHeading.input.subtitule;
  //   buttons.pt = firestoreHeading.input.button;
  // } catch {
  //
  // }
  //
  // useEffect(captarDados, [])
  // let useTitule = ''
  // let useSubtitule = ''
  // let useButton = ''
  //
  //
  // if(ctx.language === 'english') {
  //   useTitule = titules.en;
  //   useSubtitule = subTitules.en;
  //   useButton = buttons.en;
  // } else if(ctx.language === 'spanish') {
  //   useTitule = titules.es;
  //   useSubtitule = subTitules.es;
  //   useButton = buttons.es;
  // } else if(ctx.language === 'portuguese') {
  //   useTitule = titules.pt;
  //   useSubtitule = subTitules.pt;
  //   useButton = buttons.pt;
  // } else if(ctx.language === 'french') {
  //   useTitule = titules.fr;
  //   useSubtitule = subTitules.fr;
  //   useButton = buttons.fr;
  // } else if(ctx.language === 'german') {
  //   useTitule = titules.de;
  //   useSubtitule = subTitules.de;
  //   useButton = buttons.de;
  // }  else if(ctx.language === 'chinese') {
  //   useTitule = titules.zh;
  //   useSubtitule = subTitules.zh;
  //   useButton = buttons.zh;
  // }

  const useTitule = "Encare a tecnologia"
  const useSubtitule = "Tecnologias de Reconhecimento de Expressões Faciais"
  const useButton = "Saiba mais!"

  return(
    <section className="heading">
      <div className="heading__image">
        <div className='heading__fundo' />
        <div className="heading__background"></div>
      </div>
      <h1 className="heading__primary-text">
        <span className="heading__titule">{useTitule}</span><br/>
        <span className="heading__sub-titule">{useSubtitule}</span>
        <a href="#fer" className="btn heading__button">{useButton}</a>
      </h1>
      <div className="heading__logo">
        <img src={logo} alt="" class="heading__logo__image" />
      </div>
    </section>
  )
}

export default Heading;
