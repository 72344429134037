import firebase from "firebase/app";
import "firebase/auth";
import './CaixaDeCadastro.css';
import google from './logos/logo-google.png';
import facebook from './logos/logo-facebook.png';
import twitter from './logos/logo-twitter.png';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

var db = firebase.firestore();
const analytics = firebase.analytics();

const CaixaDeCadastro = (props) => {
  const passarEstadoDaCaixaDeCadastro = () => {
    props.colherEstadoDaCaixaDeCadastro()
  }

  const passarEstadoDaCaixaDeLogin = () => {
    props.colherEstadoDaCaixaDeCadastro()
    props.colherEstadoDaCaixaDeLogin()
  }

  const [emailDeLogin, setEmailDeLogin] = useState('');
  const [senhaDeLogin, setSenhaDeLogin] = useState('');

  const atualizarEmail = () => {
    setEmailDeLogin(document.getElementById('input-email').value)
  }

  const atualizarSenha = () => {
    setSenhaDeLogin(document.getElementById('input-senha').value)
  }

  const verficarLogin = (user) => {
    db.collection('customers')
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          analytics.logEvent('acess_login');
        } else {
          analytics.logEvent('create_login');
        }
      })
  }

  const fazerCadastro = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(emailDeLogin, senhaDeLogin)
      .then((userCredential) => {
        var user = userCredential.user;
        props.colherEstadoDaCaixaDeCadastro()
        if(props.estadoDoBotaoDeCompra) {
          const subscribe = async() => {
            const docRef = await db
            .collection('customers')
            .doc(user.uid)
            .collection('checkout_sessions')
            .add({
              price: 'price_1J6QXRI6pYNUcMpACdQKRhGz',
              success_url: window.location.origin + '/face-emotions/action',
              cancel_url: window.location.origin + '/face-emotions/',
            });
            docRef.onSnapshot(async (snap) => {
              const { error, sessionId } = snap.data();
              if (error) {
                alert(`An error occured: ${error.message}`);
              }
              if (sessionId) {
                const stripe = await loadStripe('pk_live_51J4SwXI6pYNUcMpAl5oMtBk72YdytZhjEXtWzsQJCvRFSqduwxzSLNH8KdUCit8vMFoir1SO38GAK6Qka8oK3um400pOzCP5E2');
                stripe.redirectToCheckout({ sessionId });
              }
            })
          }
          subscribe();
          props.passarLoading();
          analytics.logEvent('load_checkout');
        } else {
          alert('Bem vindo', emailDeLogin)
          analytics.logEvent('create_login');
        }
      })
      .catch((error) => {
        console.error(error.code);
        console.error(error.massage);
        alert('Falha ao cadastrar!')
      })
  }

  const fazerLoginComGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth()
    .signInWithPopup(provider)
    .then((result) => {
    /** @type {firebase.auth.OAuthCredential} */
    var credential = result.credential;

    // This gives you a Google Access Token. You can use it to access the Google API.
    var token = credential.accessToken;
    // The signed-in user info.
    var user = result.user;
    props.colherEstadoDaCaixaDeCadastro()
    verficarLogin(user)
    alert('Bem vindo')
  }).catch((error) => {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    // ...
    alert('Falha na autentificação Google');
  });
  }

  const fazerLoginComFacebook = () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().useDeviceLanguage();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // The signed-in user info.
        var user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var accessToken = credential.accessToken;

        props.colherEstadoDaCaixaDeCadastro()
        verficarLogin(user)
        alert('Bem vindo')
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        alert('Falha na autentificação com Facebook');
      });
  }

  const fazerLoginComTwitter = () => {
    firebase.auth().useDeviceLanguage();
    var provider = new firebase.auth.TwitterAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        var token = credential.accessToken;
        var secret = credential.secret;

        // The signed-in user info.
        var user = result.user;
        props.colherEstadoDaCaixaDeCadastro()
        verficarLogin(user)
        alert('Bem vindo')
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        alert('Falha na autentificação com Twitter')
      });
  }

  return(
    <>
      <div className='caixa-de-cadastro__background' onClick={passarEstadoDaCaixaDeCadastro}></div>
      <div className='caixa-de-cadastro'>
        <div className='caixa-de-cadastro__centro'>
          <div className='caixa-de-cadastro__exit' onClick={passarEstadoDaCaixaDeCadastro}>
            <div className='caixa-de-cadastro__exit-1'></div>
            <div className='caixa-de-cadastro__exit-2'></div>
          </div>
          <div className='caixa-de-cadastro__caixa-central'>
            <h2 className='caixa-de-cadastro__titulo'>Criar Conta</h2>
            <form>
              <div className='caixa-de-cadastro__cadastrarGoogle' onClick={fazerLoginComGoogle}><img src={google}></img>Continuar com Google</div><br />
              <div className='caixa-de-cadastro__cadastrarFacebook' onClick={fazerLoginComFacebook}><img src={facebook}></img>Continuar com Facebook</div><br />
              <div className='caixa-de-cadastro__cadastrarTwitter' onClick={fazerLoginComTwitter}><img src={twitter}></img>Continuar com Twitter</div>
              <p className='caixa-de-cadastro__texto'>ou</p>
              <label className='texto-email' for='input-email'>Email: </label><br />
              <input type='email' id='input-email' onChange={atualizarEmail}></input><br />
              <label className='texto-senha' for='input-senha'>Senha: </label><br />
              <input type='password' id='input-senha' onChange={atualizarSenha}></input><br />
              <div className='caixa-de-cadastro__cadastrarEmail' onClick={fazerCadastro}>Criar Conta</div><br />
              <a className='caixa-de-cadastro__possue-login' onClick={passarEstadoDaCaixaDeLogin}>Já possue um login?</a>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default CaixaDeCadastro;
