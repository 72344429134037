import './App.css';
import { useState, useContext, useEffect } from 'react';
import firebase from "firebase";
import "firebase/auth";

import LanguageContext from './context/language-context';
import FirebaseStart from './context/firebase-start';

import Heading from './components/pagina-principal/Heading/Heading';
import Center from './components/pagina-principal/Center/Center';
import Promocao from './components/pagina-principal/Promocao/Promocao';
import Ferramentas from './components/pagina-principal/Ferramentas/Ferramentas';
import Footering from './components/pagina-principal/Footering/Footering';
import Loading from './components/pagina-principal/Loading/Loading';
import TermosDeUso from './components/pagina-principal/TermosDeUso/TermosDeUso';
import PoliticaDePrivacidade from './components/pagina-principal/PoliticaDePrivacidade/PoliticaDePrivacidade';
import PesquisaDeSatisfacao from './components/pagina-principal/PesquisaDeSatisfacao/PesquisaDeSatisfacao';
import Tradutor from './components/pagina-principal/Tradutor/Tradutor';
// import CaixaDeRecuperarSenha from './components/pagina-principal/CaixaDeRecuperarSenha/CaixaDeRecuperarSenha';

import Navbar from './components/pagina-tech-facs/Navbar/Navbar';
import BarraDeLogin from './components/pagina-principal/BarraDeLogin/BarraDeLogin';
import CaixaDeCadastro from './components/pagina-principal/CaixaDeCadastro/CaixaDeCadastro';
import CaixaDeLogin from './components/pagina-principal/CaixaDeLogin/CaixaDeLogin';
import Header from './components/pagina-tech-facs/Header/Header';
import Ferramenta from './components/pagina-tech-facs/Ferramenta/Ferramenta';
import Instrucoes from './components/pagina-tech-facs/Instrucoes/Instrucoes';
import Footer from './components/pagina-tech-facs/Footer/Footer';

import HeaderVendas from './components/pagina-vendas-tech-facs/HeaderVendas/HeaderVendas';
import TextoVendas from './components/pagina-vendas-tech-facs/TextoVendas/TextoVendas';
import PromoverVendas from './components/pagina-vendas-tech-facs/PromoverVendas/PromoverVendas';
import Comparacao from './components/pagina-vendas-tech-facs/Comparacao/Comparacao';
import Explicacao from './components/pagina-vendas-tech-facs/Explicacao/Explicacao';
import BotaoDeTeste from './components/pagina-vendas-tech-facs/BotaoDeTeste/BotaoDeTeste';

function App() {
  const ctxFire = useContext(FirebaseStart);
  ctxFire.firebaseStart()

  const db = firebase.firestore();

  const [estadoDaEmocao, setEstadoDaEmocao] = useState('neutro');
  const [escolhaDaPagina, setEscolhaDaPagina] = useState('pagina principal');
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [estadoDaCaixaDeCadastro, setEstadoDaCaixaDeCadastro] = useState(false);
  const [estadoDaCaixaDeLogin, setEstadoDaCaixaDeLogin] = useState(false);
  const [estadoDoTermosDeUso, setEstadoDoTermosDeUso] = useState(false);
  const [estadoDaPoliticaDePrivacidade, setEstadoDaPoliticaDePrivacidade] = useState(false);
  const [renderizarAreaDeMembros, setRenderizarAreaDeMembros] = useState(false);
  const [estadoDoBotaoDeCompra, setEstadoDoBotaoDeCompra] = useState('');
  const [ativarLoading, setAtivarLoading] = useState(false);
  const [paginaInicialValida, setPaginaInicialValida] = useState('')
  const pegarEstadoDaEmocao = (estado) => {
    setEstadoDaEmocao(estado)
  };

  useEffect(
    () => {
      if(window.location.href.includes('face-emotions') && !window.location.href.includes('action')) {
        setPaginaInicialValida('face-vendas')
      } else if(window.location.href.includes('action')) {
        setPaginaInicialValida('face-ferramenta')
      } else {
        setPaginaInicialValida('pagina-inicial')
      }
    }, []
  )

  const pegarEscolhaDaPagina = (escolha) => {
    if(escolha === 'pagina principal') {
      window.history.pushState("object or string", "Title", "/")
      setPaginaInicialValida('pagina-inicial')
    }else if(escolha === 'face emotions') {
      if(renderizarAreaDeMembros) {
        window.history.pushState("object or string", "Title", "/face-emotions/action")
        setPaginaInicialValida('face-ferramenta')
      } else {
        window.history.pushState("object or string", "Title", "/face-emotions/")
        setPaginaInicialValida('face-vendas')
      }
    }else if(escolha === 'action') {
      window.history.pushState("object or string", "Title", "/face-emotions/action")
      setPaginaInicialValida('face-ferramenta')
    }else if(escolha === 'termos de uso') {
      window.history.pushState("object or string", "Title", "/termos-de-uso")
      setEstadoDoTermosDeUso(!estadoDoTermosDeUso)
    }else if(escolha === 'politica de privacidade') {
      window.history.pushState("object or string", "Title", "/politica-de-privacidade")
      setEstadoDaPoliticaDePrivacidade(!estadoDaPoliticaDePrivacidade)
    }
  };
  const pegarEstadoDaCaixaDeCadastro = (botaoDeCompraAcionado = false) => {
    setEstadoDaCaixaDeCadastro(!estadoDaCaixaDeCadastro)
    if(botaoDeCompraAcionado === true) {
      setEstadoDoBotaoDeCompra(botaoDeCompraAcionado)
    }
  };
  const pegarEstadoDaCaixaDeLogin = () => {
    setEstadoDaCaixaDeLogin(!estadoDaCaixaDeLogin)
  };
  const pegarEstadoDoTermosDeUso = () => {
    setEstadoDoTermosDeUso(!estadoDoTermosDeUso)
  };
  const pegarEstadoDaPoliticaDePrivacidade = () => {
    setEstadoDaPoliticaDePrivacidade(!estadoDaPoliticaDePrivacidade)
  };
  const ligarLoading = () => {
    setAtivarLoading(true)
  }
  const pegarSelectedLanguage = (lingua) => {
    setSelectedLanguage(lingua)
  };

  const iOS = () => {
    const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
    const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform)
    return(
      isMacLike || isIOS
    )
  }

  let caixaDeCadastro = ''
  if(estadoDaCaixaDeCadastro) {
    caixaDeCadastro = <CaixaDeCadastro passarLoading={ligarLoading} colherEstadoDaCaixaDeCadastro={pegarEstadoDaCaixaDeCadastro} colherEstadoDaCaixaDeLogin={pegarEstadoDaCaixaDeLogin} passarEstadoDoBotaoDeCompra={estadoDoBotaoDeCompra} estadoDoConteudoPremium={renderizarAreaDeMembros} />
  }
  let caixaDeLogin = ''
  if(estadoDaCaixaDeLogin) {
    caixaDeLogin = <CaixaDeLogin colherEstadoDaCaixaDeLogin={pegarEstadoDaCaixaDeLogin} />
  }
  let termosDeUso = ''
  if(estadoDoTermosDeUso || window.location.href.includes('termos-de-uso')) {
    termosDeUso = <TermosDeUso colherEstadoDoTermosDeUso={pegarEstadoDoTermosDeUso} />
  }
  let politicaDePrivacidade = ''
  if(estadoDaPoliticaDePrivacidade || window.location.href.includes('politica-de-privacidade')) {
    politicaDePrivacidade = <PoliticaDePrivacidade colherEstadoDaPoliticaDePrivacidade={pegarEstadoDaPoliticaDePrivacidade} />
  }

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      db.collection('customers')
        .doc(user.uid)
        .collection('subscriptions')
        .where('status', '==', 'active')
        .get()
        .then((querySnapshot) => {
          var contador = 0;
          querySnapshot.forEach(async (doc) => {
            if (doc.exists) {
              contador = contador + 1
            } else {
            }
          })
          if (contador != 0) {
            setRenderizarAreaDeMembros(true)
          } else {
            setRenderizarAreaDeMembros(false)
          }
        })
        .catch((error) => {
          console.log('Erro getting document:', error)
        })
    }
  })

  let loading = ''

  if (ativarLoading) {
    loading = <Loading />
  }

  const avisoApple = <div className='aviso-apple'><h2 className='aviso-apple__texto'>O site no Safari ainda está em desenvolvimento. Para ter uma experiência melhor, use outro navegador como o Chrome.</h2></div>

  const atalho = [termosDeUso, politicaDePrivacidade, caixaDeLogin, caixaDeCadastro, loading]

  if(paginaInicialValida === '') {
    return(
      <></>
    )
  }
  if(paginaInicialValida === 'face-vendas') {
    return(
      <LanguageContext.Provider
        value={{
          getSelectedLanguage: pegarSelectedLanguage,
          language: selectedLanguage,
          iosIsValid: iOS
        }}>
        {atalho}
        <HeaderVendas />
        <TextoVendas />
        <PromoverVendas />
        <BotaoDeTeste tipo='teste' conteudo='Faça o Teste grátis!' colherEscolhaDaPagina={pegarEscolhaDaPagina}/>
        <Center />
        <Comparacao />
        <BotaoDeTeste tipo='premium' conteudo='Acesse o conteúdo premium' colherEstadoDaCaixaDeCadastro={pegarEstadoDaCaixaDeCadastro} passarLoading={ligarLoading}/>
        <Footering colherEscolhaDaPagina={pegarEscolhaDaPagina} />
      </LanguageContext.Provider>
    )
  }

  if(paginaInicialValida === 'pagina-inicial') {
    return (
      <LanguageContext.Provider
        value={{
          getSelectedLanguage: pegarSelectedLanguage,
          language: selectedLanguage,
          iosIsValid: iOS
        }}>
        <div className="app pagina-principal">
          {atalho}
          <PesquisaDeSatisfacao />
          <Navbar colherEscolhaDaPagina={pegarEscolhaDaPagina} />
          <BarraDeLogin colherEstadoDaCaixaDeLogin={pegarEstadoDaCaixaDeLogin} colherEstadoDaCaixaDeCadastro={pegarEstadoDaCaixaDeCadastro} />
          <Heading />
          <Center />
          <Promocao />
          <Ferramentas colherEscolhaDaPagina={pegarEscolhaDaPagina} />
          <Footering colherEscolhaDaPagina={pegarEscolhaDaPagina} />
        </div>
      </LanguageContext.Provider>
    );
  }
  if(paginaInicialValida === 'face-ferramenta') {
    return (
      <LanguageContext.Provider
        value={{
          getSelectedLanguage: pegarSelectedLanguage,
          language: selectedLanguage,
          iosIsValid: iOS
        }}>
        <div className="app tech-facs">
          {atalho}
          <PesquisaDeSatisfacao />
          <Navbar colherEscolhaDaPagina={pegarEscolhaDaPagina} />
          <BarraDeLogin colherEstadoDaCaixaDeLogin={pegarEstadoDaCaixaDeLogin} colherEstadoDaCaixaDeCadastro={pegarEstadoDaCaixaDeCadastro} />
          <Header colherEscolhaDaPagina={pegarEscolhaDaPagina} />
          <Ferramenta colherEstadoDaEmocao={pegarEstadoDaEmocao} estadoDoConteudoPremium={renderizarAreaDeMembros} colherEstadoDaCaixaDeCadastro={pegarEstadoDaCaixaDeCadastro} passarLoading={ligarLoading} />
          <Instrucoes estadoDaEmocao={estadoDaEmocao} estadoDoConteudoPremium={renderizarAreaDeMembros} colherEstadoDaCaixaDeCadastro={pegarEstadoDaCaixaDeCadastro} />
          <Footering colherEscolhaDaPagina={pegarEscolhaDaPagina} />
        </div>
      </LanguageContext.Provider>
    );
  }
}

export default App;
