import{ useState } from 'react';
import './Ferramenta.css';

import Menu from './Menu/Menu';
import CenterImage from './CenterImage/CenterImage';

const Ferramenta = (props) => {
  const [estadoDaEmocao, setEstadoDaEmocao] = useState('neutro')
  const receberInformacaoFerramenta = (emocao) => {
    setEstadoDaEmocao('neutro')
    setEstadoDaEmocao(emocao)
    props.colherEstadoDaEmocao(emocao)
  }
  console.log()
  return(
    <section className='ferramenta'>
      <CenterImage estadoDaEmocao={estadoDaEmocao} receberInformacaoFerramenta={receberInformacaoFerramenta} estadoDoConteudoPremium={props.estadoDoConteudoPremium} />
      <Menu estadoDaEmocao={estadoDaEmocao} receberInformacaoFerramenta={receberInformacaoFerramenta} colherEstadoDaCaixaDeCadastro={props.colherEstadoDaCaixaDeCadastro} estadoDoConteudoPremium={props.estadoDoConteudoPremium} passarLoading={props.passarLoading}/>
    </section>
  )
}

export default Ferramenta;
