import './PromoverVendas.css';
import CaixaDePromocao from './CaixaDePromocao/CaixaDePromocao'

const PromoverVendas = () => {
  const subTituleProfissional = 'Desenvolva a habilidade social mais importante para o seu sucesso profissional.';
  const subTituleDeteccao = 'Saiba quando os outros estão mentindo para você ou quando estão tentando te enganar.';
  const subTituleRelacionamentos = 'Conquiste a principal habilidade para melhorar seus relacionamentos.';
  const subTituleLinguagem = 'Aprenda a linguagem corporal mais poderosa para decifrar pessoas.';
  const subTituleEmocoes = 'Descubra como saber quais emoções as outras pessoas estão sentindo pela face.';
  const subTituleVendas = 'Aumente mais de 20% suas vendas com o reconhecimento de expressões faciais.';

  return(
    <div className='promover-vendas' id='explicacao'>
      <h2 className='promover-vendas__titule'>Porque aprender</h2>
      <div className='promover-vendas__conjunto-de-caixas'>
        <CaixaDePromocao className='caixa-um' titule='Profissional' subTitule={subTituleProfissional} background='trofeu' />
        <CaixaDePromocao className='caixa-dois' titule='Detecção de mentiras' subTitule={subTituleDeteccao} background='mentiras' />
        <CaixaDePromocao className='caixa-tres' titule='Relacionamentos' subTitule={subTituleRelacionamentos} background='amigos' />
        <CaixaDePromocao className='caixa-quatro' titule='Linguagem Corporal' subTitule={subTituleLinguagem} background='escudo' />
        <CaixaDePromocao className='caixa-cinco' titule='Inteligência emocional' subTitule={subTituleEmocoes} background='coracao' />
        <CaixaDePromocao className='caixa-seis' titule='Vendas' subTitule={subTituleVendas} background='vendas' />
      </div>
    </div>
  )
}

export default PromoverVendas;
