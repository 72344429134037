import './Loading.css';

const Loading = () => {
  return(
    <div className='loading'>
      <div className='loading__loader'>
        <div className='loading__animation'></div>
      </div>
    </div>
  )
}

export default Loading;
