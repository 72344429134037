import './CaixaDeOpcoes.css';

const CaixaDeOpcoes = (props) => {
  const nomeDaClasse = 'caixa-de-opcoes ' + props.className
  return(
    <div className={nomeDaClasse}>{props.children}</div>
  )
}

export default CaixaDeOpcoes;
