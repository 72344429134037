import './Footering.css';

const Footering = (props) => {
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const coletarEscolhaDaPagina = (escolha) => {
    props.colherEscolhaDaPagina(escolha);
    topFunction();
  }

  return(
    <section className="footering">
      <div className="footering__navbar">
        <a href="" className="footering__navbar__home" onClick={() => {coletarEscolhaDaPagina('pagina principal')}}>Página Inicial</a>
        <a href="" className="footering__navbar__emocoes" onClick={() => {coletarEscolhaDaPagina('face emotions')}}>Face Emotions</a>
        <a href="" className="footering__navbar__treino" onClick={() => {coletarEscolhaDaPagina('termos de uso')}}>Termos de Uso</a>
        <a href="" className="footering__navbar__reconhecimento" onClick={() => {coletarEscolhaDaPagina('politica de privacidade')}}>Política de Privacidade</a>
      </div>
    </section>
  )
}

export default Footering;
