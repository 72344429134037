import './Center.css';

const Center = () => {
  return(
    <section className="center">
      <div className="center__titule">
        <h2 className="secundary-titule">Organizações que utilizam Reconhecimento de Expressões Faciais</h2>
      </div>
      <div className="center__images">
        <div className="center__images-apple" />
        <div className="center__images-harvard" />
        <div className="center__images-cia" />
        <div className="center__images-brown" />
        <div className="center__images-nypd" />
        <div className="center__images-kpmg" />
        <div className="center__images-disney" />
        <div className="center__images-salk-caixa"><div className="center__images-salk" /></div>
      </div>
    </section>
  )
}

export default Center;
