import { useState } from 'react';
import './Menu.css';

import MenuBar from './MenuBar/MenuBar';
import BottomBar from './BottomBar/BottomBar';
import AusMenu from './AusMenu/AusMenu';
import AreaDeCompra from './AreaDeCompra/AreaDeCompra';

const Menu = (props) => {
  const [opcaoPrincipal, setOpcaoPrincipal] = useState('EMOÇÕES');
  const pegarOpcaoPrincipal = (opcao) => {
    setOpcaoPrincipal(opcao);
  }
  const receberInformacao = (emocao) => {
    props.receberInformacaoFerramenta(emocao)
  }

  function range(start, end) {
    let ans = [];
    for (let i = start; i <= end; i++) {
        ans.push('' + i);
      }
    return ans;
  }

  const numeros = range(0, 50);

  if(opcaoPrincipal === 'AUS' && !props.estadoDoConteudoPremium) {
    return(
      <div className='menu'>
        <MenuBar estadoDaEmocao={props.estadoDaEmocao} pegarOpcaoPrincipalMenu={pegarOpcaoPrincipal} receberInformacaoMenu={receberInformacao} />
        <AreaDeCompra passarLoading={props.passarLoading} colherEstadoDaCaixaDeCadastro={props.colherEstadoDaCaixaDeCadastro} />
      </div>
    )
  }else if(numeros.includes(props.estadoDaEmocao) && !props.estadoDoConteudoPremium) {
    return(
      <div className='menu'>
        <MenuBar estadoDaEmocao={props.estadoDaEmocao} pegarOpcaoPrincipalMenu={pegarOpcaoPrincipal} receberInformacaoMenu={receberInformacao} />
        <AreaDeCompra colherEstadoDaCaixaDeCadastro={props.colherEstadoDaCaixaDeCadastro} />
      </div>
    )
  }else if(opcaoPrincipal === 'AUS' || numeros.includes(props.estadoDaEmocao) && props.estadoDoConteudoPremium) {
    return(
      <div className='menu'>
        <MenuBar estadoDaEmocao={props.estadoDaEmocao} pegarOpcaoPrincipalMenu={pegarOpcaoPrincipal} receberInformacaoMenu={receberInformacao} />
        <AusMenu estadoDaEmocao={props.estadoDaEmocao} passarEstadoDoBotaoMenu={receberInformacao} />
      </div>
    )
  }else if(opcaoPrincipal === 'EMOÇÕES') {
    return(
      <div className='menu'>
        <MenuBar estadoDaEmocao={props.estadoDaEmocao} pegarOpcaoPrincipalMenu={pegarOpcaoPrincipal} receberInformacaoMenu={receberInformacao} />
        <BottomBar receberInformacaoMenu={receberInformacao} />
      </div>
    )
  }else if(opcaoPrincipal === 'OUTRAS') {
    return(
      <div className='menu'>
        <MenuBar estadoDaEmocao={props.estadoDaEmocao} pegarOpcaoPrincipalMenu={pegarOpcaoPrincipal} receberInformacaoMenu={receberInformacao} />
        <div className='menu__outras'>
          <p>Em breve...</p>
        </div>
      </div>
    )
  }
  return(
    <div className='menu'>
      <MenuBar estadoDaEmocao={props.estadoDaEmocao} pegarOpcaoPrincipalMenu={pegarOpcaoPrincipal} receberInformacaoMenu={receberInformacao} />
    </div>
  )
}

export default Menu;
