import { useState, useEffect, useContext } from 'react';
import './BarraDeLogin.css';
import firebase from "firebase/app";
import "firebase/auth";

const db = firebase.firestore();

const BarraDeLogin = (props) => {
  const [estadoDoLogin, setEstadoDoLogin] = useState(false);
  const passarEstadoDaCaixaDeLogin = () => {
    props.colherEstadoDaCaixaDeLogin()
  }
  const passarEstadoDaCaixaDeCadastro = () => {
    props.colherEstadoDaCaixaDeCadastro()
  }

  const fazerLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        alert('Você saiu da conta')
      })
      .catch((error) => {
        console.error(error.code);
        console.error(error.massage);
        alert('Falha ao sair')
      })
  }

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setEstadoDoLogin(true)
      var uid = user.uid;
    } else {
      setEstadoDoLogin(false)
    }
  });

  if(estadoDoLogin) {
    return(
      <div className='barra-de-login'>
        <a className='barra-de-login__logout' onClick={fazerLogout}>Sair da Conta</a>
      </div>
    )
  }else {
    return(
      <div className='barra-de-login'>
        <a className='barra-de-login__login' onClick={passarEstadoDaCaixaDeLogin}>Acessar Conta</a>
        <a className='barra-de-login__cadastro' onClick={passarEstadoDaCaixaDeCadastro}>Criar Conta</a>
      </div>
    )
  }
}

export default BarraDeLogin;
