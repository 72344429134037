import { useContext } from 'react';
import './HeaderVendas.css';
import Deck from './Deck/Deck';
import LanguageContext from './../../../context/language-context';
import logo from './../../pagina-principal/images/facing-tech.png';

function topFunction() {
  window.scrollBy(0,810)
}

const HeaderVendas = () => {
  const ctx = useContext(LanguageContext);

  let classeDoHeaderTexto
  console.log(ctx.iosIsValid())
  if(ctx.iosIsValid() === true) {
    classeDoHeaderTexto = 'apple-header-vendas__texto'
  }

  return(
    <div className='header-vendas'>
      <img className='header-vendas__logo' src={logo}></img>
      <div className={'header-vendas__texto ' + classeDoHeaderTexto}>
        <h1 className='header-vendas__titule'>FACE EMOTIONS</h1>
        <h2 className='header-vendas__sub-titule'>Aprenda como reconhecer emoções na face pelas Micro Expressões Faciais</h2>
        <a className='header-vendas__button' href='#explicacao'>Saiba Mais</a>
      </div>
      <Deck />
    </div>
  )
}

export default HeaderVendas;
