import './CenterImage.css';
import AusBar from './AusBar/AusBar';
import MarcadorDeAu from './MarcadorDeAu/MarcadorDeAu'

const CenterImage = (props) => {
  const levarInformacaoAteAus = (informacao) => {
    props.receberInformacaoFerramenta(informacao);
  }

  if(props.estadoDaEmocao === 'alegria') {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center alegria-img'>
              <MarcadorDeAu au='6' className='alegria__au-6' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
              <MarcadorDeAu au='12' className='alegria__au-12' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
          <AusBar estadoDaEmocao={props.estadoDaEmocao} />
        </div>
      </div>
    )
  } else if(props.estadoDaEmocao === 'tristeza') {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center tristeza-img'>
            <MarcadorDeAu au='1' className='tristeza__au-1' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            <MarcadorDeAu au='4' className='tristeza__au-4' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            <MarcadorDeAu au='15' className='tristeza__au-15' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            <MarcadorDeAu au='17' className='tristeza__au-17' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            <MarcadorDeAu au='41' className='tristeza__au-41' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
          <AusBar estadoDaEmocao={props.estadoDaEmocao} />
        </div>
      </div>
    )
  } else if(props.estadoDaEmocao === 'raiva') {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center raiva-img'>
              <MarcadorDeAu au='4' className='raiva__au-4' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
              <MarcadorDeAu au='5' className='raiva__au-5' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
              <MarcadorDeAu au='7' className='raiva__au-7' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
              <MarcadorDeAu au='23' className='raiva__au-23' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
              <MarcadorDeAu au='29' className='raiva__au-29' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
          <AusBar estadoDaEmocao={props.estadoDaEmocao} />
        </div>
      </div>
    )
  } else if(props.estadoDaEmocao === 'nojo') {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center nojo-img'>
              <MarcadorDeAu au='9' className='nojo__au-9' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
              <MarcadorDeAu au='17' className='nojo__au-17' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
          <AusBar estadoDaEmocao={props.estadoDaEmocao} />
        </div>
      </div>
    )
  } else if(props.estadoDaEmocao === 'desprezo') {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center desprezo-img'>
              <MarcadorDeAu au='14' className='desprezo__au-14' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
          <AusBar estadoDaEmocao={props.estadoDaEmocao} />
        </div>
      </div>
    )
  } else if(props.estadoDaEmocao === 'medo') {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center medo-img'>
              <MarcadorDeAu au='1' className='medo__au-1' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
              <MarcadorDeAu au='2' className='medo__au-2' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
              <MarcadorDeAu au='4' className='medo__au-4' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
              <MarcadorDeAu au='5' className='medo__au-5' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
              <MarcadorDeAu au='20' className='medo__au-20' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
          <AusBar estadoDaEmocao={props.estadoDaEmocao} />
        </div>
      </div>
    )
  } else if(props.estadoDaEmocao === 'surpresa') {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center surpresa-img'>
            <MarcadorDeAu au='1' className='surpresa__au-1' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            <MarcadorDeAu au='2' className='surpresa__au-2' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            <MarcadorDeAu au='5' className='surpresa__au-5' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            <MarcadorDeAu au='25' className='surpresa__au-25' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            <MarcadorDeAu au='26' className='surpresa__au-26' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
          <AusBar estadoDaEmocao={props.estadoDaEmocao} />
        </div>
      </div>
    )
  } else if(props.estadoDaEmocao === 'neutro') {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center'></div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '1' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-1-img'>
              <MarcadorDeAu au='1' className='au-1' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '2' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-2-img'>
              <MarcadorDeAu au='2' className='au-2' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '4' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-4-img'>
              <MarcadorDeAu au='4' className='au-4' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '5' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-5-img'>
              <MarcadorDeAu au='5' className='au-5' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '6' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-6-img'>
              <MarcadorDeAu au='6' className='au-6' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '7' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-7-img'>
              <MarcadorDeAu au='7' className='au-7' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '9' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-9-img'>
              <MarcadorDeAu au='9' className='au-9' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '10' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-10-img'>
              <MarcadorDeAu au='10' className='au-10' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '11' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-11-img'>
              <MarcadorDeAu au='11' className='au-11' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '12' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-12-img'>
              <MarcadorDeAu au='12' className='au-12' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '13' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-13-img'>
              <MarcadorDeAu au='13' className='au-13' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '14' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-14-img'>
              <MarcadorDeAu au='14' className='au-14' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '15' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-15-img'>
              <MarcadorDeAu au='15' className='au-15' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '16' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-16-img'>
              <MarcadorDeAu au='16' className='au-16' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '17' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-17-img'>
              <MarcadorDeAu au='17' className='au-17' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '18' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-18-img'>
              <MarcadorDeAu au='18' className='au-18' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '20' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-20-img'>
              <MarcadorDeAu au='20' className='au-20' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '21' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-21-img'>
              <MarcadorDeAu au='21' className='au-21' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '22' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-22-img'>
              <MarcadorDeAu au='22' className='au-22' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '23' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-23-img'>
              <MarcadorDeAu au='23' className='au-23' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '24' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center  au-24-img'>
              <MarcadorDeAu au='24' className='au-24' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '25' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-25-img'>
              <MarcadorDeAu au='25' className='au-25' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '26' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-26-img'>
              <MarcadorDeAu au='26' className='au-26' tipoDoMarcador='alto-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '27' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-27-img'>
              <MarcadorDeAu au='27' className='au-27' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '28' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-28-img'>
              <MarcadorDeAu au='28' className='au-28' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '29' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-29-img'>
              <MarcadorDeAu au='29' className='au-29' tipoDoMarcador='alto-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '41' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-41-img'>
              <MarcadorDeAu au='41' className='au-41' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '43' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-43-img'>
              <MarcadorDeAu au='43' className='au-43' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '45' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-45-img'>
              <MarcadorDeAu au='45' className='au-45' tipoDoMarcador='baixo-esquerda-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if(props.estadoDaEmocao === '46' && props.estadoDoConteudoPremium) {
    return(
      <div className='center-image'>
        <div className='center-image__box'>
          <div className='center-image__image'>
            <div className='center-image__center au-46-img'>
              <MarcadorDeAu au='46' className='au-46' tipoDoMarcador='baixo-direita-1' levarInformacaoAteAus={levarInformacaoAteAus} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return(
    <div className='center-image'>
      <div className='center-image__box'>
        <div className='center-image__image'>
          <div className='center-image__center'></div>
        </div>
      </div>
    </div>
  );
}

export default CenterImage;
