import './Comparacao.css';

import certo from './img/check.png'
import errado from './img/cross.png'

const Comparacao = () => {
  return(
    <section className='comparacao'>
      <h2 className='comparacao__titule'>Comparação</h2>
      <table className='comparacao__tabela'>
        <tr className='comparacao__linha-1'>
          <th id='coluna-1' className='linha-1 l1-c1'></th>
          <th id='coluna-2' className='linha-1'>Manual FACS</th>
          <th id='coluna-3' className='linha-1'>Curso Presencial</th>
          <th id='coluna-4' className='linha-1 celula-do-lado'>Curso Online</th>
          <th className='linha-1 celula-especial'>Face Emotions</th>
        </tr>
        <tr>
          <th id='coluna-1' className='linha-2'>Preço?</th>
          <td id='coluna-2' className='linha-2'>≅ R$1.900,00</td>
          <td id='coluna-3' className='linha-2'>≅ R$1.000,00</td>
          <td id='coluna-4' className='linha-2'>≅ R$500,00</td>
          <td id='coluna-5' className='linha-2'>R$25,90/mês</td>
        </tr>
        <tr>
          <th id='coluna-1' className='linha-3'>Em português?</th>
          <td id='coluna-2' className='linha-3'><img src={errado} className='certo' /></td>
          <td id='coluna-3' className='linha-3'><img src={certo} className='certo' /></td>
          <td id='coluna-4' className='linha-3'><img src={certo} className='certo' /></td>
          <td id='coluna-5' className='linha-3'><img src={certo} className='certo' /></td>
        </tr>
        <tr>
          <th id='coluna-1' className='linha-4'>Fácil de entender?</th>
          <td id='coluna-2' className='linha-4'><img src={errado} className='certo' /></td>
          <td id='coluna-3' className='linha-4'><img src={certo} className='certo' /></td>
          <td id='coluna-4' className='linha-4'><img src={certo} className='certo' /></td>
          <td id='coluna-5' className='linha-4'><img src={certo} className='certo' /></td>
        </tr>
        <tr>
          <th id='coluna-1' className='linha-5'>Ferramenta de treino?</th>
          <td id='coluna-2' className='linha-5'><img src={errado} className='certo' /></td>
          <td id='coluna-3' className='linha-5'><img src={errado} className='certo' /></td>
          <td id='coluna-4' className='linha-5'><img src={errado} className='certo' /></td>
          <td id='coluna-5' className='linha-5 c5-l5'>Em breve...</td>
        </tr>
      </table>
    </section>
  )
}

export default Comparacao;
