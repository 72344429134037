import { useState } from 'react';
import './AusMenu.css';
import OpcoesDeAu from './OpcoesDeAu/OpcoesDeAu';

const AusMenu = (props) => {
  const [acionandoBotao, setAcionandoBotao] = useState('')
  const mudarCorBotao = (auDoBotao) => {
    props.passarEstadoDoBotaoMenu(auDoBotao);
    setAcionandoBotao(auDoBotao);
  }

  let valorUm = ''
  let valorDois = ''
  let valorQuatro = ''
  let valorCinco = ''
  let valorSeis = ''
  let valorSete = ''
  let valorNove = ''
  let valorDez = ''
  let valorOnze = ''
  let valorDoze = ''
  let valorTreze = ''
  let valorCatorze = ''
  let valorQuinze = ''
  let valorDezesseis = ''
  let valorDezessete = ''
  let valorDezoito = ''
  let valorVinte = ''
  let valorVinteUm = ''
  let valorVinteDois = ''
  let valorVinteTres = ''
  let valorVinteQuatro = ''
  let valorVinteCinco = ''
  let valorVinteSeis = ''
  let valorVinteSete = ''
  let valorVinteOito = ''
  let valorVinteNove = ''
  let valorQuarentaUm = ''
  let valorQuarentaDois = ''
  let valorQuarentaTres = ''
  let valorQuarentaQuatro = ''


  if(acionandoBotao === '1' || props.estadoDaEmocao === '1') {
    valorUm = 'acionado'
  }else if(acionandoBotao === '2' || props.estadoDaEmocao === '2') {
    valorDois = 'acionado'
  }else if(acionandoBotao === '4' || props.estadoDaEmocao === '4') {
    valorQuatro = 'acionado'
  }else if(acionandoBotao === '5' || props.estadoDaEmocao === '5') {
    valorCinco = 'acionado'
  }else if(acionandoBotao === '6' || props.estadoDaEmocao === '6') {
    valorSeis = 'acionado'
  }else if(acionandoBotao === '7' || props.estadoDaEmocao === '7') {
    valorSete = 'acionado'
  }else if(acionandoBotao === '9' || props.estadoDaEmocao === '9') {
    valorNove = 'acionado'
  }else if(acionandoBotao === '10' || props.estadoDaEmocao === '10') {
    valorDez = 'acionado'
  }else if(acionandoBotao === '11' || props.estadoDaEmocao === '11') {
    valorOnze = 'acionado'
  }else if(acionandoBotao === '12' || props.estadoDaEmocao === '12') {
    valorDoze = 'acionado'
  }else if(acionandoBotao === '13' || props.estadoDaEmocao === '13') {
    valorTreze = 'acionado'
  }else if(acionandoBotao === '14' || props.estadoDaEmocao === '14') {
    valorCatorze = 'acionado'
  }else if(acionandoBotao === '15' || props.estadoDaEmocao === '15') {
    valorQuinze = 'acionado'
  }else if(acionandoBotao === '16' || props.estadoDaEmocao === '16') {
    valorDezesseis = 'acionado'
  }else if(acionandoBotao === '17' || props.estadoDaEmocao === '17') {
    valorDezessete = 'acionado'
  }else if(acionandoBotao === '18' || props.estadoDaEmocao === '18') {
    valorDezoito = 'acionado'
  }else if(acionandoBotao === '20' || props.estadoDaEmocao === '20') {
    valorVinte = 'acionado'
  }else if(acionandoBotao === '21' || props.estadoDaEmocao === '21') {
    valorVinteUm = 'acionado'
  }else if(acionandoBotao === '22' || props.estadoDaEmocao === '22') {
    valorVinteDois = 'acionado'
  }else if(acionandoBotao === '23' || props.estadoDaEmocao === '23') {
    valorVinteTres = 'acionado'
  }else if(acionandoBotao === '24' || props.estadoDaEmocao === '24') {
    valorVinteQuatro = 'acionado'
  }else if(acionandoBotao === '25' || props.estadoDaEmocao === '25') {
    valorVinteCinco = 'acionado'
  }else if(acionandoBotao === '26' || props.estadoDaEmocao === '26') {
    valorVinteSeis = 'acionado'
  }else if(acionandoBotao === '27' || props.estadoDaEmocao === '27') {
    valorVinteSete = 'acionado'
  }else if(acionandoBotao === '28' || props.estadoDaEmocao === '28') {
    valorVinteOito = 'acionado'
  }else if(acionandoBotao === '29' || props.estadoDaEmocao === '29') {
    valorVinteNove = 'acionado'
  }else if(acionandoBotao === '41' || props.estadoDaEmocao === '41') {
    valorQuarentaUm = 'acionado'
  }else if(acionandoBotao === '43' || props.estadoDaEmocao === '43') {
    valorQuarentaTres = 'acionado'
  }else if(acionandoBotao === '45' || props.estadoDaEmocao === '45') {
    valorQuarentaQuatro = 'acionado'
  }else if(acionandoBotao === '46' || props.estadoDaEmocao === '46') {
    valorQuarentaDois = 'acionado'
  }


  return(
    <div className='aus-menu'>
      <OpcoesDeAu au='1' className={'aus-menu__1 ' + valorUm} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='2' className={'aus-menu__2 ' + valorDois} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='4' className={'aus-menu__4 ' + valorQuatro} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='5' className={'aus-menu__5 ' + valorCinco} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='6' className={'aus-menu__6 ' + valorSeis} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='7' className={'aus-menu__7 ' + valorSete} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='9' className={'aus-menu__9 ' + valorNove} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='10' className={'aus-menu__10 ' + valorDez} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='11' className={'aus-menu__11 ' + valorOnze} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='12' className={'aus-menu__12 ' + valorDoze} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='13' className={'aus-menu__13 ' + valorTreze} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='14' className={'aus-menu__14 ' + valorCatorze} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='15' className={'aus-menu__15 ' + valorQuinze} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='16' className={'aus-menu__16 ' + valorDezesseis} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='17' className={'aus-menu__17 ' + valorDezessete} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='18' className={'aus-menu__18 ' + valorDezoito} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='20' className={'aus-menu__20 ' + valorVinte} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='21' className={'aus-menu__21 ' + valorVinteUm} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='22' className={'aus-menu__22 ' + valorVinteDois} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='23' className={'aus-menu__23 ' + valorVinteTres} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='24' className={'aus-menu__24 ' + valorVinteQuatro} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='25' className={'aus-menu__25 ' + valorVinteCinco} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='26' className={'aus-menu__26 ' + valorVinteSeis} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='27' className={'aus-menu__27 ' + valorVinteSete} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='28' className={'aus-menu__28 ' + valorVinteOito} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='29' className={'aus-menu__29 ' + valorVinteNove} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='41' className={'aus-menu__41 ' + valorQuarentaUm} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='43' className={'aus-menu__43 ' + valorQuarentaTres} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='45' className={'aus-menu__45 ' + valorQuarentaQuatro} estadoDoBotao={mudarCorBotao} />
      <OpcoesDeAu au='46' className={'aus-menu__46 ' + valorQuarentaDois} estadoDoBotao={mudarCorBotao} />
    </div>
  )
}

export default AusMenu;
