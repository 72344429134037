import './Navbar.css';
import firebase from "firebase/app";
import FirebaseStart from './../../../context/firebase-start';
import { useState, useEffect, useContext } from 'react';

const Navbar = (props) => {
  var mybutton = document.getElementById("opcao");

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const coletarEscolhaDaPagina = (escolha) => {
    props.colherEscolhaDaPagina(escolha);
    document.getElementById("nav").click();
    topFunction();
  }

  return(
    <section class='navbar'>
      <input type="checkbox" class="navbar__checkbox" id="nav"></input>
      <label for="nav" class="navbar__button">
        <div class="navbar__hamburguer-center"></div>
        <div class="navbar__hamburguer-top"></div>
        <div class="navbar__hamburguer-bottom"></div>
      </label>
      <div class="navbar__background"></div>
      <ul class="navbar__background__list">
        <li class="navbar__background__list-home" id="opcao"><a onClick={() => {coletarEscolhaDaPagina('pagina principal')}}>1- Página Inicial</a></li>
        <li class="navbar__background__list-emocoes" id="opcao"><a onClick={() => {coletarEscolhaDaPagina('face emotions')}}>2- Face Emotions</a></li>
        <li class="navbar__background__list-treino"><a onClick={() => {coletarEscolhaDaPagina('termos de uso')}}>3- Termos de Uso</a></li>
        <li class="navbar__background__list-reconhecimento"><a onClick={() => {coletarEscolhaDaPagina('politica de privacidade')}}>4- Política de Privacidade</a></li>
      </ul>
    </section>
  );
};

export default Navbar;
