import './Header.css';
import logo from "./facing-tech.png";

const Header = (props) => {
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const coletarEscolhaDaPagina = (escolha) => {
    props.colherEscolhaDaPagina(escolha);
    topFunction();
  }

  return(
    <div className='header'>
      <div className='header__logo'>
        <img className='header__imagem' src={logo} onClick={() => {coletarEscolhaDaPagina('pagina principal')}} />
      </div>
      <h1 className='header__titule'>face emotions</h1>
      <h2 className='header__text'>Desenvolva a habilidade de reconhecer emoções na face</h2>
    </div>
  )
};

export default Header;
