import React from 'react';
import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyAqqCMONTvdeToiiqQYxH8I5OBn_-wR7VM",
  authDomain: "facing-tech-ffbb6.firebaseapp.com",
  projectId: "facing-tech-ffbb6",
  storageBucket: "facing-tech-ffbb6.appspot.com",
  messagingSenderId: "18092821396",
  appId: "1:18092821396:web:612883ef48fe16f21d9d81",
  measurementId: "G-7YSWV2GPD4"
};

const start = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }else {
     firebase.app();
  }
}

start()
const db = firebase.firestore();

const captarDadosGet = (componente, setName) => {
  let firestoreData = db
                  .collection('translations')
                  .doc(componente)
                  .get()
                  .then((doc) => {
                    return doc.data()
                  })
  var fs = Promise.resolve(firestoreData);
  fs.then((data) => {
    setName(data)
  })
  return null;
}

const traduzirItensGet = (name, lista) => {
  let itensTraduzidos = []
  for(var item in lista) {
    try {
      item = name.translated.item
      itensTraduzidos.push(item)
    } catch {}
  }
  return itensTraduzidos;
}

const pegarItensTraduzidosGet = (ctx, itensTraduzidos) => {
  const linguas = [['english', 'en'], ['spanish', 'es'], ['portuguese', 'pt'], ['french', 'fr'], ['german', 'de'], ['chinese', 'zh']];

  let itensUsados = []
  for(var lingua in linguas) {
    if(lingua[0] === ctx.language) {
      for(item in itensTraduzidos) {
        itensUsados.push(item.lingua[1])
      }
      var item = itensTraduzidos.lingua[1]
      itensUsados.push(item)
    }
  }
  return(itensUsados)
}

const LanguageContext = React.createContext({
  language: 'english',
  captarDados: captarDadosGet,
  traduzirItens: traduzirItensGet,
  pegarItensTraduzidos: pegarItensTraduzidosGet
});

export default LanguageContext;
