import { useState } from 'react';
import './BottomBar.css';
import EmotionChoice from './EmotionChoice/EmotionChoice';
import CaixaDeOpcoes from './../../../../../layout/CaixaDeOpcoes/CaixaDeOpcoes'

const BottomBar = (props) => {
  const [estadoDoBotaoDeEmocoes, setEstadoDoBotaoDeEmocoes] = useState('neutro');
  const receberInformacao = (emocao) => {
    props.receberInformacaoMenu(emocao);
    setEstadoDoBotaoDeEmocoes(emocao);
  }

  let alegria = '';
  let tristeza = '';
  let raiva = '';
  let nojo = '';
  let desprezo = '';
  let medo = '';
  let surpresa = '';
  let neutro = '';

  if(estadoDoBotaoDeEmocoes === 'alegria') {
    alegria = 'acionado'
  }else if(estadoDoBotaoDeEmocoes === 'tristeza') {
    tristeza = 'acionado'
  }else if(estadoDoBotaoDeEmocoes === 'raiva') {
    raiva = 'acionado'
  }else if(estadoDoBotaoDeEmocoes === 'nojo') {
    nojo = 'acionado'
  }else if(estadoDoBotaoDeEmocoes === 'desprezo') {
    desprezo = 'acionado'
  }else if(estadoDoBotaoDeEmocoes === 'medo') {
    medo = 'acionado'
  }else if(estadoDoBotaoDeEmocoes === 'surpresa') {
    surpresa = 'acionado'
  }else if(estadoDoBotaoDeEmocoes === 'neutro') {
    neutro = 'acionado'
  }
  return(
    <CaixaDeOpcoes className='bottom-bar'>
      <div className='bottom-bar__emotions'>
        <EmotionChoice className={'alegria ' + alegria} receberInformacao={receberInformacao} />
        <EmotionChoice className={'tristeza ' + tristeza} receberInformacao={receberInformacao} />
        <EmotionChoice className={'raiva ' + raiva} receberInformacao={receberInformacao} />
        <EmotionChoice className={'nojo ' + nojo} receberInformacao={receberInformacao} />
        <EmotionChoice className={'desprezo ' + desprezo} receberInformacao={receberInformacao} />
        <EmotionChoice className={'medo ' + medo} receberInformacao={receberInformacao} />
        <EmotionChoice className={'surpresa ' + surpresa} receberInformacao={receberInformacao} />
        <EmotionChoice className={'neutro ' + neutro} receberInformacao={receberInformacao} />
      </div>
    </CaixaDeOpcoes>
  );
}

export default BottomBar;
