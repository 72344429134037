import { useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import LanguageContext from './../../../context/language-context';

import './BotaoDeTeste.css';

var db = firebase.firestore();
const analytics = firebase.analytics();

const BotaoDeTeste = (props) => {
  const ctx = useContext(LanguageContext);
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const botaoDeCompraApertado = async () => {
    analytics.logEvent('click_buy_button_pagina_de_vendas');
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const subscribe = async() => {
          const docRef = await db
          .collection('customers')
          .doc(user.uid)
          .collection('checkout_sessions')
          .add({
            price: 'price_1J6QXRI6pYNUcMpACdQKRhGz',
            success_url: window.location.origin + '/face-emotions/action',
            cancel_url: window.location.origin+ '/face-emotions/',
          });
          docRef.onSnapshot(async (snap) => {
            const { error, sessionId } = snap.data();
            if (error) {
              alert(`An error occured: ${error.message}`);
            }
            if (sessionId) {
              const stripe = await loadStripe('pk_live_51J4SwXI6pYNUcMpAl5oMtBk72YdytZhjEXtWzsQJCvRFSqduwxzSLNH8KdUCit8vMFoir1SO38GAK6Qka8oK3um400pOzCP5E2');
              stripe.redirectToCheckout({ sessionId });
            }
          })
        }
        subscribe();
        props.passarLoading()
        analytics.logEvent('load_checkout_pagina_de_vendas');
        var uid = user.uid;
      }else {
        props.colherEstadoDaCaixaDeCadastro(true)
      }
    });
  }

  const coletarEscolhaDaPagina = () => {
    if(props.tipo === 'teste') {
      props.colherEscolhaDaPagina('action');
      topFunction();
      analytics.logEvent('redirect_to_test');
    } else if(props.tipo === 'premium') {
      botaoDeCompraApertado();
    }
  }

  let classeDoBotaoConteudo
  let classeDoBotaoBackground
  if(ctx.iosIsValid()) {
    classeDoBotaoConteudo = 'dispositivo-ios__conteudo'
    classeDoBotaoBackground = 'dispositivo-ios__background'
  }

  return(
    <div className='botao-de-teste'>
      <div className={'botao-de-teste__conteudo ' + classeDoBotaoConteudo} onClick={coletarEscolhaDaPagina}>
        <div className={'botao-de-teste__background ' + classeDoBotaoBackground}></div>
        <p className='botao-de-teste__texto'>{props.conteudo}</p>
      </div>
    </div>
  )
}

export default BotaoDeTeste;
