import './RealilzarLogin.css';

const RealilzarLogin = (props) => {
  return(
    <div className='realilzar-login'>
      <h2 className='realilzar-login__titulo'>Já possue sua conta grátis?</h2>
      <p className='realilzar-login__sub-titulo'>Crie a sua conta grátis agora para acessar o conteúdo</p>
      <div className='realilzar-login__button' onClick={props.colherEstadoDaCaixaDeCadastro}>Criar Conta</div>
    </div>
  )
}

export default RealilzarLogin;
