import { useSpring, animated } from 'react-spring';
import './OpcoesDeInstrucoes.css';

const OpcoesDeInstrucoes = (props) => {
  const animacao = useSpring({
    from: {
      transform: 'scale(0)'
    },
    to: {
      transform: 'scale(1)'
    },
  })
  const nomeDaClasse = 'opcoes-de-instrucoes ' + props.className;
  const passarOpcao = () => {
    props.colherOpcao(props.className.split('__')[1]);
  };
  function range(start, end) {
    let ans = [];
    for (let i = start; i <= end; i++) {
        ans.push('' + i);
      }
    return ans;
  }

  const numeros = range(0, 50);

  let textoDasOpcoes = '';
  if(props.className === 'instrucoes__introducao') {
    textoDasOpcoes = 'Introdução'
  }else if(props.className === 'instrucoes__emocoes') {
    textoDasOpcoes = 'Emoções'
  }else if(props.className === 'instrucoes__aus') {
    textoDasOpcoes = 'AUs'
  }else if(props.className === 'instrucoes__especifico') {
    if(numeros.includes(props.estadoDaEmocao)) {
      textoDasOpcoes = 'AU: ' + props.estadoDaEmocao
    }else {
      textoDasOpcoes = props.estadoDaEmocao
    }
  }

  return(
    <animated.div style={animacao} className={nomeDaClasse} onClick={passarOpcao}>
      <p className='opcoes-de-instrucoes__texto'>{textoDasOpcoes}</p>
    </animated.div>
  );
}

export default OpcoesDeInstrucoes;
