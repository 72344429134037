import './Ferramentas.css';

import tech from './../images/tech-facs_image.png'
import faces from './../images/faces-background.png'

const Ferramentas = (props) => {
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const coletarEscolhaDaPagina = (escolha) => {
    props.colherEscolhaDaPagina(escolha);
    document.getElementById("nav").click();
    topFunction();
  }

  return(
    <section className="ferramentas" id="fer">
      <h2 className="ferramentas__titule">Ferramentas de Reconhecimento de Expressões Faciais</h2>
      <div className="ferramentas__grid">
        <div className="ferramentas__emocoes">
          <div className="ferramentas__emocoes-frente">
            <h2 className="ferramentas__emocoes-frente__text">
              <span className="ferramentas__emocoes-frente__text__titule">Tech facs</span><br/>
              <span className="ferramentas__emocoes-frente__text__sub-titule">Descubra como reconhecer as emoções na face pelo movimento dos músculos faciais.</span>
            </h2>
            <img src={tech} alt="" className="ferramentas__emocoes-frente__imagem" />
            <div className="ferramentas__emocoes-frente__imagem-background" />
          </div>
          <div className="ferramentas__emocoes-tras">
            <div className="ferramentas__emocoes-tras__botao" onClick={() => {coletarEscolhaDaPagina('face emotions')}}>Clique aqui</div>
          </div>
        </div>
        <div className="ferramentas__treino">
          <div className="ferramentas__treino-frente">
            <h2 className="ferramentas__treino-frente__text">
              <span className="ferramentas__treino-frente__text__titule">X-face</span><br />
              <span className="ferramentas__treino-frente__text__sub-titule">Desenvolva sua habilidade de reconhecer emoções na prática treinando com vídeos reais.</span>
            </h2>
            <img src={faces} alt="" className="ferramentas__treino-frente__imagem" />
            <div className="ferramentas__treino-frente__imagem-background" />
          </div>
          <div className="ferramentas__treino-tras">
            <h2 className="ferramentas__treino-tras__titule">Em breve...</h2>
          </div>
        </div>
        <div className="ferramentas__reconhecimento">
          <div className="ferramentas__reconhecimento-frente">
            <h2 className="ferramentas__reconhecimento-frente__text">
              <span className="ferramentas__reconhecimento-frente__text__titule">X-face</span><br/>
              <span className="ferramentas__reconhecimento-frente__text__sub-titule">Reconhaça as emoções de pessoas em vídeos utilizando inteligência artificial.</span>
            </h2>
            <img src={faces} alt="" className="ferramentas__reconhecimento-frente__imagem" />
            <div className="ferramentas__reconhecimento-frente__imagem-background" />
          </div>
          <div className="ferramentas__reconhecimento-tras">
            <h2 className="ferramentas__reconhecimento-tras__titule">Em breve...</h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Ferramentas;
