import { useState } from 'react';
import './CaixaDeLogin.css';
import google from './../CaixaDeCadastro/logos/logo-google.png';
import facebook from './../CaixaDeCadastro/logos/logo-facebook.png';
import twitter from './../CaixaDeCadastro/logos/logo-twitter.png';
import firebase from "firebase/app";
import "firebase/auth";

var db = firebase.firestore();
const analytics = firebase.analytics();

const CaixaDeLogin = (props) => {
  const passarEstadoDaCaixaDeLogin = () => {
    props.colherEstadoDaCaixaDeLogin()
  }

  const [emailDeLogin, setEmailDeLogin] = useState('');
  const [senhaDeLogin, setSenhaDeLogin] = useState('');

  const atualizarEmail = () => {
    setEmailDeLogin(document.getElementById('input-email').value)
  }

  const atualizarSenha = () => {
    setSenhaDeLogin(document.getElementById('input-senha').value)
  }

  const verficarLogin = (user) => {
    db.collection('customers')
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          analytics.logEvent('acess_login');
        } else {
          analytics.logEvent('create_login');
        }
      })
  }

  const fazerLogin = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(emailDeLogin, senhaDeLogin)
      .then((userCredential) => {
        var user = userCredential.user;
        console.log(userCredential)
        props.colherEstadoDaCaixaDeLogin()
        analytics.logEvent('acess_login');
        alert('Bem vindo', emailDeLogin)
      })
      .catch((error) => {
        console.error(error.code);
        console.error(error.massage);
        alert('Falha ao acessar a conta!')
      })
  }

  const fazerLoginComGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth()
    .signInWithPopup(provider)
    .then((result) => {
    /** @type {firebase.auth.OAuthCredential} */
    var credential = result.credential;
    // This gives you a Google Access Token. You can use it to access the Google API.
    var token = credential.accessToken;
    // The signed-in user info.
    var user = result.user;
    props.colherEstadoDaCaixaDeLogin()
    verficarLogin(user)
    alert('Bem vindo')
  }).catch((error) => {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    // ...
    alert('Falha na autentificação Google');
  });
  }

  const fazerLoginComFacebook = () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().useDeviceLanguage();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // The signed-in user info.
        var user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var accessToken = credential.accessToken;
        verficarLogin(user)
        props.colherEstadoDaCaixaDeLogin()
        alert('Bem vindo')
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        alert('Falha na autentificação Facebook');
      });
  }

  const fazerLoginComTwitter = () => {
    firebase.auth().useDeviceLanguage();
    var provider = new firebase.auth.TwitterAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        var token = credential.accessToken;
        var secret = credential.secret;

        // The signed-in user info.
        var user = result.user;
        props.colherEstadoDaCaixaDeLogin()
        verficarLogin(user)
        alert('Bem vindo')
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        alert('Falha na autentificação Twitter');
      });
  }

  return(
    <>
      <div className='caixa-de-login__background' onClick={passarEstadoDaCaixaDeLogin}></div>
      <div className='caixa-de-login'>
        <div className='caixa-de-login__centro'>
          <div className='caixa-de-login__exit' onClick={passarEstadoDaCaixaDeLogin}>
            <div className='caixa-de-login__exit-1'></div>
            <div className='caixa-de-login__exit-2'></div>
          </div>
          <div className='caixa-de-login__caixa-central'>
            <h2 className='caixa-de-login__titulo'>Acessar Conta</h2>
            <form>
              <div className='caixa-de-login__cadastrarGoogle' onClick={fazerLoginComGoogle}><img src={google}></img>Continuar com Google</div><br />
              <div className='caixa-de-login__cadastrarFacebook' onClick={fazerLoginComFacebook}><img src={facebook}></img>Continuar com Facebook</div><br />
              <div className='caixa-de-login__cadastrarTwitter' onClick={fazerLoginComTwitter}><img src={twitter}></img>Continuar com Twitter</div>
              <p className='caixa-de-login__texto'>ou</p>
              <label className='texto-email' for='input-email'>Email: </label><br />
              <input type='email' id='input-email' onChange={atualizarEmail}></input><br />
              <label className='texto-senha' for='input-senha'>Senha: </label><br />
              <input type='password' id='input-senha' onChange={atualizarSenha}></input><br />
              <div className='caixa-de-login__cadastrarEmail' onClick={fazerLogin}>Acessar Conta</div><br />
              <a className='caixa-de-login__possue-login'>Esqueceu a senha?</a>
            </form>
          </div>
        </div>
      </div>
    </>
  )}

export default CaixaDeLogin;
