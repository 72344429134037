import './CaixaDePromocao.css';

import mentira from './../icones/lie.png'

const CaixaDePromocao = (props) => {
  const nomeDaClasse = 'caixa-de-promocao ' + props.className
  const nomeDoBackground = 'caixa-de-promocao__circle ' + props.background

  return(
    <div className={nomeDaClasse}>
      <div className={nomeDoBackground}></div>
      <div className='caixa-de-promocao__caixa-de-texto'>
        <h2 className='caixa-de-promocao__titule'>{props.titule}</h2>
        <p className='caixa-de-promocao__text'>{props.subTitule}</p>
      </div>
    </div>
  )
}

export default CaixaDePromocao;
